import React from 'react';
import { Box, Container, CssBaseline } from "@mui/material";
import Header from "../../components/Header";
import OrganisationAddForm from "../../components/OrganisationAddForm";

const AddOrganisation = () => {
  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Add New Organisation" subtitle="Create and add a new organisation to the system" />   
            </Box>
            <OrganisationAddForm />
        </Container>
    </React.Fragment>
  );
};

export default AddOrganisation;