import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { tokens } from "../theme";
import { Box, Button, IconButton, Typography, useTheme, useMediaQuery, TextField } from "@mui/material";
import Header from "./Header";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 as uuidv4 } from 'uuid';

const steps = ['Specify device identification', 'Specify name settings', 'Configure your device'];

const DeviceAddForm = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = useState({
    id: uuidv4(),
    avatar: "https://firebasestorage.googleapis.com/v0/b/darwin-f2b67.appspot.com/o/app%2Fassets%2Fdevice.jpg?alt=media&token=ca42a49a-b6d0-48d0-a616-2ee3081599fc",
    name: "",
    model: "",
    sn: "",
    created: "",
    config: {
      deveui: "",
      probe1: {
        red: {
          alias: ""
        },
        green: {
          alias: ""
        },
        blue: {
          alias: ""
        }
      },
      probe2: {
        red: {
          alias: ""
        },
        green: {
          alias: ""
        },
        blue: {
          alias: ""
        }
      }
    }
  });

  const handleSubmit = async () => {
    setLoading(true);
    //console.log(formData);
    setLoading(false);
  };

  const handleNext = () => {
    var currStep = activeStep;

    if( currStep + 1 === steps.length ){
      handleSubmit();
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = () => (event) => {
    const { name, value } = event.target;
    if( name === "name" ){
      setFormData((prevData) => ({
        ...prevData,
        name: value
      }));
    }else if( name === "type" ){
      setFormData((prevData) => ({
        ...prevData,
        model: value
      }));
    }else if( name === "sn" ){
      setFormData((prevData) => ({
        ...prevData,
        sn: value
      }));
    }else if( name === "deveui" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          deveui: value
        }
      }));
    }else if( name === "p1RedAlias" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          probe1: {
            ...prevData["config"]["probe1"],
            red: {
              ...prevData["config"]["probe1"]["red"],
              alias: value
            }
          }
        }
      }));
    }else if( name === "p1GreenAlias" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          probe1: {
            ...prevData["config"]["probe1"],
            green: {
              ...prevData["config"]["probe1"]["green"],
              alias: value
            }
          }
        }
      }));
    }else if( name === "p1BlueAlias" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          probe1: {
            ...prevData["config"]["probe1"],
            blue: {
              ...prevData["config"]["probe1"]["blue"],
              alias: value
            }
          }
        }
      }));
    }else if( name === "p2RedAlias" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          probe2: {
            ...prevData["config"]["probe2"],
            red: {
              ...prevData["config"]["probe2"]["red"],
              alias: value
            }
          }
        }
      }));
    }else if( name === "p2GreenAlias" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          probe2: {
            ...prevData["config"]["probe2"],
            green: {
              ...prevData["config"]["probe2"]["green"],
              alias: value
            }
          }
        }
      }));
    }else if( name === "p2BlueAlias" ){
      setFormData((prevData) => ({
        ...prevData,
        config: {
          ...prevData["config"],
          probe2: {
            ...prevData["config"]["probe2"],
            blue: {
              ...prevData["config"]["probe2"]["blue"],
              alias: value
            }
          }
        }
      }));
    }
  };

  return (
    <React.Fragment>
        <CssBaseline />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
        {activeStep === steps.length ? (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >Back</Button>
              </Box>
            </React.Fragment>
        ) : (
            <React.Fragment>
                {getStepContent(activeStep, isXs, formData, handleChange)}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >Back</Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </Box>
            </React.Fragment>
        )}
    </React.Fragment>
  );
};

const getStepContent = (step, xs, formData, handleChange) => {
  switch (step) {
    case 0:
      return (
        <Step2 isXs={xs} formData={formData} handleChange={handleChange}/>
        
      );
    case 1:
      return (
        <Step1 isXs={xs} formData={formData} handleChange={handleChange} />
      );
    case 2:
      return (
        <Step3 isXs={xs} formData={formData} handleChange={handleChange}/>
      );
    default:
      return 'Unknown step';
  }
};


const Step1 = ({isXs, formData, handleChange}) => {
    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="Left"
                                display={{ xs: 'none', md: 'block' }}
                            >
                              Identification
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                          <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Name"}</Typography>
                                    <TextField
                                    required
                                    name="name"
                                    label={isXs ? "Name" : ""}
                                    fullWidth
                                    height="80px"
                                    helperText="Please enter a useful name for your device"
                                    onChange={handleChange()}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
};

const Step2 = ({isXs, formData, handleChange}) => {
  return (
      <Container>
          <Box sx={{ width: '100%' }}>
              <Container>
              <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="Left"
                                display={{ xs: 'none', md: 'block' }}
                            >
                              Hardware Model
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                          <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Device Type"}</Typography>
                                    <TextField
                                        required
                                        name="type"
                                        label={isXs ? "Device Type" : ""}
                                        fullWidth
                                        height="80px"
                                        helperText="Please select the device model"
                                        type="select"
                                        onChange={handleChange()}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                          <Typography
                              variant="h5"
                              align="Left"
                              display={{ xs: 'none', md: 'block' }}
                          >
                            Serial Number
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                        <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                          <Box>
                              <Box sx={{marginTop: 0}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "S/N"}</Typography>
                                  <TextField
                                  required
                                  name="sn"
                                  label={isXs ? "S/N" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Please enter the serial number of the device found on the label"
                                  onChange={handleChange()}
                                  />
                              </Box>
                          </Box>
                      </Grid>
                  </Grid>
                  
              </Container>
          </Box>
      </Container>
  );
};

const Step3 = ({isXs, formData, handleChange}) => {
  return (
      <Container>
          <Box sx={{ width: '100%' }}>
              <Container>
              <Grid container spacing={2}>
                      <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                          <Typography
                              variant="h5"
                              align="Left"
                              display={{ xs: 'none', md: 'block' }}
                          >
                            Device ID
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                        <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                          <Box>
                              <Box sx={{marginTop: 0}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Device EUI"}</Typography>
                                  <TextField
                                  required
                                  name="deveui"
                                  label={isXs ? "Device EUI" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Please enter the unique device ID"
                                  onChange={handleChange()}
                                  />
                              </Box>
                          </Box>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                      <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                          <Typography
                              variant="h5"
                              align="Left"
                              display={{ xs: 'none', md: 'block' }}
                          >
                            Probe 1 Configuration
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                        <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                          <Box>
                              <Typography variant="h6" sx={{color: "#555555"}}>{!isXs ? "" : "Probe 1"}</Typography>
                              <Box sx={{marginTop: 0}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Red Alias"}</Typography>
                                  <TextField
                                  required
                                  name="p1RedAlias"
                                  label={isXs ? "Red Alias" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Give a useful name for the red probe location i.e Hot send"
                                  onChange={handleChange()}
                                  />
                              </Box>
                              <Box sx={{marginTop: 2}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Green Alias"}</Typography>
                                  <TextField
                                  required
                                  name="p1GreenAlias"
                                  label={isXs ? "Green Alias" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Give a useful name for the green probe location i.e Hot return"
                                  onChange={handleChange()}
                                  />
                              </Box>
                              <Box sx={{marginTop: 2}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Blue Alias"}</Typography>
                                  <TextField
                                  required
                                  name="p1BlueAlias"
                                  label={isXs ? "Blue Alias" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Give a useful name for the blue probe location i.e Cold send"
                                  onChange={handleChange()}
                                  />
                              </Box>
                          </Box>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                      <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                          <Typography
                              variant="h5"
                              align="Left"
                              display={{ xs: 'none', md: 'block' }}
                          >
                            Probe 2 Configuration
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                        <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                          <Box>
                              <Typography variant="h6" sx={{color: "#555555"}}>{!isXs ? "" : "Probe 2"}</Typography>
                              <Box sx={{marginTop: 0}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Red Alias"}</Typography>
                                  <TextField
                                  required
                                  name="p2RedAlias"
                                  label={isXs ? "Red Alias" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Give a useful name for the red probe location i.e Hot send"
                                  onChange={handleChange()}
                                  />
                              </Box>
                              <Box sx={{marginTop: 2}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Green Alias"}</Typography>
                                  <TextField
                                  required
                                  name="p2GreenAlias"
                                  label={isXs ? "Green Alias" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Give a useful name for the green probe location i.e Hot return"
                                  onChange={handleChange()}
                                  />
                              </Box>
                              <Box sx={{marginTop: 2}}>
                                  <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Blue Alias"}</Typography>
                                  <TextField
                                  required
                                  name="p2BlueAlias"
                                  label={isXs ? "Blue Alias" : ""}
                                  fullWidth
                                  height="80px"
                                  helperText="Give a useful name for the blue probe location i.e Cold send"
                                  onChange={handleChange()}
                                  />
                              </Box>
                          </Box>
                      </Grid>
                  </Grid>
              </Container>
          </Box>
      </Container>
  );
};
export default DeviceAddForm;