import React, { useEffect, useState } from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';

import {
    DataGrid,
    GridRowEditStopReasons,
    GridActionsCellItem
  } from '@mui/x-data-grid';

export const DevicesTable = ({rowData, setRowData, orgs, clients}) => {
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleGetClientColumnData = (value, row) => {
        return "";
    };

    const handleOrganisationChange = (id, newOrgId) => {
        const updatedRows = rowData.map((row) =>
          row.id === id ? { ...row, org: orgs.find((org) => org.id === newOrgId) } : row
        );
        setRowData(updatedRows);
    };

    const handleEditClick = (id) => {
        //console.log(id);
    };

    const handleAssignClick = (id) => {
        //console.log(id);
    };

    const columns = [
        { 
            field: 'alias',
            headerName: 'Name',
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: true 
        },
        { 
            field: 'model',  
            headerName: 'Model', 
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: true
        },
        { 
            field: 'id',  
            headerName: 'Unique ID', 
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: true
        },
        { 
            field: 'sn',  
            headerName: 'Serial Number', 
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: true
        },
        {
            field: 'orgName',  
            headerName: 'Organisation',
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: true,
            valueGetter: (params) => params.row.org.name
        },
        /*
        { 
            field: 'clientName',  
            headerName: 'Client',
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: false,
            valueGetter: (params) => params.row.client.name
        },
        { 
            field: 'locationName',  
            headerName: 'Location',
            width: 180, 
            align: 'left', 
            headerAlign: 'left', 
            editable: false,
            valueGetter: (params) => params.row.locid.name
        },
        */
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditClick(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Assign">
                        <IconButton onClick={() => handleAssignClick(params.row)}>
                            <AssignmentIcon />
                        </IconButton>
                    </Tooltip>
                </>
              )
        }
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                backgroundColor: '#ffffff',
                borderRadius: 2
            }}
        >
            <DataGrid
              rows={rowData}
              columns={columns}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
            />
        </Box>
    );
};

export default DevicesTable;