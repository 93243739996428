import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import {MeasurementSelectTable} from '../../components/client/MeasurementSelectTable';
import {MeasurementChart} from '../../components/MeasurementChart';
import Header from "../../components/Header";
import { getUserOrg } from '../../data/database';
import { useAuth } from '../../components/AuthContext';
import { getDatabase, ref, get, query } from 'firebase/database';

const Measurements = () => {
    const { isAuthenticated, uid } = useAuth();
    const [org, setOrg] = useState('');
    const [client, setClient] = useState('');
    const [devId, setDevId] = useState('');

    async function getUserOrg(){
        if( isAuthenticated() ){
            if( uid !== "" ){
                const db = getDatabase();
                const dbRef = ref(db, `darwin/udb/users/list/${uid}`);
                const q = query(dbRef);
                const snapshot = await get(q);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    return data;
                }else{
                    throw new Error("User doesn't exist.");
                }
            }else{
                throw new Error("Invalid authentication. Please sign in and try again.");
            }
        }else{
            throw new Error("Not authenticated to view this page. Please sign in and try again.");
        }
    }

    useEffect(() => {
        //Get the user org and client
        getUserOrg()
            .then( (result) => {
                //console.log(result);
            })
            .catch( (error) => {
                //console.log(error);
            });
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Header title="Measurements" subtitle="Select a device to view measurement data" />
                <MeasurementChart devEui={devId} />
                <MeasurementSelectTable setDevEui={setDevId} />
            </Container>
        </React.Fragment>
    );
};

export default Measurements;