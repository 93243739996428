// ClientCard.jsx
import React from 'react';
import { Card, CardContent, Avatar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ClientCard = ({ org, client }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/client`, {state:{orgid: org, cliid: client.id, cliname: client.name}});
    };

    return (
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 4, margin: 2, borderRadius: 5, cursor: 'pointer' }}
              onClick={handleCardClick}
        >
            <Avatar alt={client.name} src={client.avatar} sx={{ width: 72, height: 72, marginRight: 2 }} />
            <CardContent>
                <Typography variant="h4" component="div">
                {client.name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                {client.email}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ClientCard;
