import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme, Avatar, Typography, CssBaseline, Container, Grid, Card, CardContent, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import WelcomeCard from '../../components/WelcomeCard';
import { getOrganisationList } from '../../data/database';
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Welcome = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  
  const [organisations, setOrganisations] = React.useState([]);

  const handleCardClick = (org) => {
    navigate(`/dashboard`);
  };

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Grid container spacing={2} justifyContent={organisations.length === 1 ? 'center' : 'flex-start'}>
                    {organisations.map((org, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={6} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <WelcomeCard key={index} org={org} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    </React.Fragment>
  );
}

export default Welcome;