import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { getCoordinates } from './geocoding';

let DefaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const SetViewOnClick = ({ coords }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(coords);
    }, [coords, map]);
    return null;
};

const LocationMap = ({ address }) => {
    const [position, setPosition] = useState([51.505, -0.09]); // Default position

    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                if( address.addrline1 || address.addrline2 || address.towncity || address.county || address.postcode ){
                    const addressParts = [address.addrline1, address.addrline2, address.towncity, address.county, address.postcode, address.country];
                    const filteredAddressParts = addressParts.filter(part => part && part.trim() !== '');
                    const addressString = filteredAddressParts.join(', ');
                    const { lat, lon } = await getCoordinates(addressString);
                    setPosition([lat, lon]);
                }
            } catch (error) {
                //console.error('Error setting map position:', error);
            }
        };

        fetchCoordinates();
    }, [address]);

    return (
        <MapContainer style={{ height: '100%', width: '100%' }} center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
                <Popup>
                    Address: {address}
                </Popup>
            </Marker>
            <SetViewOnClick coords={position} />
        </MapContainer>
    );
};

export default LocationMap;