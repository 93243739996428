import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme, useMediaQuery, TextField } from "@mui/material";
import Header from "../../components/Header";
import UserAddForm from "../../components/UserAddForm";
import StatusBar from "../../components/StatusBar";

const AddUser = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));
  const colors = tokens(theme.palette.mode);
  const [access, setAccess] = useState([]);
  const [status, setStatus] = useState("");

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Add User" subtitle="Add a new user to the system" />
            </Box>
            <UserAddForm />
            <StatusBar text={status} enabled={status !== ""} />
        </Container>
    </React.Fragment>
  );
};

export default AddUser;