import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, useTheme} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { UsersTable } from '../../components/UsersTable';
import { getUsers } from '../../data/database';
import { ColorModeContext, tokens } from "../../theme";
import Header from "../../components/Header";

const Users = () => {
  //<Chip variant="outlined" onDelete={handleDelete} avatar={<Avatar src="/static/images/avatar/1.jpg" />} />
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    getUsers()
      .then( (userData) => {
        //console.log(userData);
        setRows(userData);
      })
      .catch( () => {

      })
  }, []);

  const handleAddUser = () => {
    navigate('/users/add');
  };

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Users" subtitle="Manage the users that have access to your system" />
                <Button
                  sx={{
                      backgroundColor: "#5562AC",
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: '#7785AC',
                        boxShadow: 'none',
                      }
                  }}
                  onClick={handleAddUser}
                >
                    <AddIcon sx={{ mr: "10px" }} />
                    Add User
                </Button>
            </Box>
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsersTable rows={rows} />
              </Grid>
            </Grid>
        </Container>
    </React.Fragment>
  );
};

export default Users;