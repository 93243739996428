// src/geocoding.js
import axios from 'axios';

export const getCoordinates = async (address) => {
    const url = 'https://nominatim.openstreetmap.org/search';
    try {
        const response = await axios.get(url, {
            params: {
                q: address,
                format: 'json',
                addressdetails: 1,
                limit: 1,
            },
        });
        if (response.data.length > 0) {
            const { lat, lon } = response.data[0];
            return { lat: parseFloat(lat), lon: parseFloat(lon) };
        } else {
            throw new Error('No results found');
        }
    } catch (error) {
        //console.error('Error fetching coordinates:', error);
        throw error;
    }
};
