import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import { Grid } from "@mui/material";
//import Grid from '@mui/material/Unstable_Grid2';
import Header from "../../components/Header";
import SummaryCard from "../../components/SummaryCard";
import Notifications from "../../components/client/Notifications";
import { useAuth } from '../../components/AuthContext';
import { getUserData, getClientData, getLocationList, getClientDeviceList, getClientStats } from '../../data/database';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [numLocations, setNumLocations]         = useState(0);
  const [numDevices, setNumDevices]             = useState(0);
  const [numActiveDevices, setNumActiveDevices] = useState(0);
  const [numUsers, setNumUsers]                 = useState(0);
  const [batteryWarnings, setBatteryWarnings]   = useState(0);
  const [warnings, setWarnings]                 = useState(0);
  const [issues, setIssues]                     = useState(0);
  const [minDevTemp, setMinDevTemp]             = useState(0);
  const [maxDevTemp, setMaxDevTemp]             = useState(0);
  const [notificationData, setNotificationData] = useState(
    [
      //{ type: 'Alert', device: 'Sensor A', trigger: 'High Temp', severity: 'High', msg: 'Temperature exceeds threshold', when: '2024-07-24 14:00' },
      //{ type: 'Warning', device: 'Sensor B', trigger: 'Low Battery', severity: 'Medium', msg: 'Battery level is low', when: '2024-07-24 13:00' }
    ]
  );

  const { isAdmin, isOrg, uid } = useAuth();
  const [orgId, setOrgId] = useState(undefined);
  const [cliId, setCliId] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [locations, setLocations] = useState(undefined);

  useEffect(() => {
    //console.log("Client Render");
    if( locations === undefined ){
        getUserData(uid)
            .then( (obj) => {
                //console.log(obj);
                if( obj.hasOwnProperty("orgs") === true ){
                  const orgIds = Object.keys(obj.orgs);
                  //console.log(orgIds);
                  if( orgIds.length > 0 && obj.orgs[orgIds[0]] === true ){
                      setOrgId(orgIds[0]);
                  }
                }

                if( obj.hasOwnProperty("clients") === true ){
                  const clientIds = Object.keys(obj.clients);
                  //console.log(clientIds);
                  if( clientIds.length > 0 && obj.clients[clientIds[0]] === true ){
                      setCliId(clientIds[0]);
                  }
                }
            })
            .catch( (err) => {
              //console.log(err);
            });
    }
  });

  useEffect( () => {
    //console.log("Organisation retrieved");
  },[orgId]);

  useEffect( () => {
    //console.log("Client retrieved");
    getClientData(cliId)
        .then( (obj) => {
            setClient(obj);
        })
        .catch( (err) => {
          //console.log(err);
        });

    getClientDeviceList(cliId)
      .then( (obj) => {
          setNumDevices(Object.keys(obj).length);
      })
      .catch();

  },[cliId]);

  useEffect( () => {
      //console.log("Fetching locations...");
      if( orgId !== undefined && cliId !== undefined ){
          getLocationList(orgId, cliId).then( (obj) => {
              var arr = [];
              Object.keys(obj).forEach( item => {
                  //console.log(obj[item]);
                  arr.push(obj[item]);
              });

              setLocations(arr);
          });
      }
  }, [client, orgId, cliId]);

  useEffect( () => {
    //console.log("Fetching stats...");
    if( orgId !== undefined && cliId !== undefined ){
        getClientStats(orgId, cliId).then( (obj) => {
            //console.log(obj);
            setNumActiveDevices(obj.numdevice['24h']);
        });
    }
}, [orgId, cliId]);

  useEffect( () => {
    //console.log("Locations Change Render");
  }, [locations]);

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Dashboard" subtitle="Welcome to your dashboard" />   
            </Box>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <SummaryCard
                          title="Total Locations"
                          total={locations && locations.length}
                          color="success"
                          icon={<img alt="icon" src="/assets/client_not_selected.png" width="64px" />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <SummaryCard
                          title="Total Devices"
                          total={numDevices}
                          color="success"
                          icon={<img alt="icon" src="/assets/darwin_thermo_1.png" width="48px" />}
                          subtitle="Unique devices seen in the last month"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <SummaryCard
                          title="Devices Seen"
                          total={numDevices + "/" + numDevices}
                          color="success"
                          icon={<img alt="icon" src="/assets/darwin_thermo_1.png" width="48px" />}
                          subtitle="Devices seen in last 24h"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <SummaryCard
                          title="Battery Warnings"
                          total={batteryWarnings}
                          color="success"
                          icon={<BatteryAlertIcon sx={{ fontSize: 40, color: "#aa0000" }} />}
                          subtitle="Battery health issues"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <SummaryCard
                          title="Warnings"
                          total={warnings}
                          color="success"
                          icon={<WarningAmberIcon sx={{ fontSize: 40, color: "#db9b35" }}/>}
                          subtitle="Potential issues"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <SummaryCard
                          title="Issues"
                          total={issues}
                          color="success"
                          icon={<PriorityHighIcon sx={{ fontSize: 40, color: "#d92d21" }}/>}
                          subtitle="High priority issues"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <SummaryCard
                          title="Device Min/Max Temp"
                          total={minDevTemp + "°C / " + maxDevTemp + "°C"}
                          color="success"
                          icon={<ThermostatIcon sx={{ fontSize: 40, color: "#42f572" }}/>}
                          subtitle="Minimum and maximum ambient temperature"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Notifications data={notificationData} />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    </React.Fragment>
  );
};

export default Dashboard;