import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';

import {
    DataGrid,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';

export const UsersTable = ({rows}) => {
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleGetClientColumnData = (value, row) => {
        return "";
    };

    const columns = [
        { 
          field: 'name',
          headerName: 'Name',
          width: 180, 
          align: 'left', 
          headerAlign: 'left', 
          editable: false 
        },
        { 
          field: 'email',  
          headerName: 'Email', 
          width: 180, 
          align: 'left', 
          headerAlign: 'left', 
          editable: false
        },
        {
          field: 'access',
          headerName: 'Access', 
          width: 180,
          align: 'left', 
          headerAlign: 'left', 
          editable: false
        },
        { 
          field: 'orgs',    
          headerName: 'Organisation', 
          flex: 1,
          align: 'left', 
          headerAlign: 'left', 
          editable: false,
          renderCell: (params) => (
            <div>
                {params && params.value ? (
                    Object.keys(params.value).map((org, index) => (
                      <Chip key={index} label={params.value[org].name} style={{ margin: 2 }} />
                    ))
                ) : (
                    <div>No Organisations</div>
                )}
            </div>
          )
        },
        { 
          field: 'clients',    
          headerName: 'Client',       
          flex: 1,
          align: 'left', 
          headerAlign: 'left', 
          editable: false,
          renderCell: (params) => (
            <div>
                {params && params.value ? (
                    Object.keys(params.value).map((client, index) => (
                      <Chip key={index} label={params.value[client].name} style={{ margin: 2 }} />
                    ))
                ) : (
                    <div>No Clients</div>
                )}
            </div>
          )
        }
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                backgroundColor: '#ffffff',
                borderRadius: 2
            }}
        >
            <DataGrid
              rows={rows}
              columns={columns}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
            />
        </Box>
    );
};

export default UsersTable;