import { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsInputSvideoOutlinedIcon from '@mui/icons-material/SettingsInputSvideoOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useAuth } from '../../components/AuthContext';
import { getUserData, getClientData } from '../../data/database';

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
                backgroundColor: selected === title ? "#7785AC" : "#f5f7f9"
            }}
            onClick={() => setSelected(title)}
            icon={icon}
            component={<Link to={to} />}
        >
        <Typography>{title}</Typography>
        </MenuItem>
    );
};

const AdminMenu = ({isCollapsed, colors, selected, setSelected}) => {
    return (
        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
                title="Dashboard"
                to="/dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}>{isCollapsed ? "Mgmt" : "Management"}</Typography>

            <Item
                title="Organisations"
                to="/organisations"
                icon={<HomeWorkOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Users"
                to="/users"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Devices"
                to="/devices"
                icon={<SensorsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>
        </Box>
    );
};

const OrgMenu = ({isCollapsed, colors, selected, setSelected}) => {
    return (
        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
                title="Dashboard"
                to="/dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}>{isCollapsed ? "Mgmt" : "Management"}</Typography>

            <Item
                title="Clients"
                to="/clients"
                icon={<HomeWorkOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>
{/** 
            <Item
                title="Users"
                to="/users"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Hubs"
                to="/hubs"
                icon={<DeviceHubOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>
*/}
            <Item
                title="Devices"
                to="/devices"
                icon={<SensorsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}>Account</Typography>

            <Item
                title="Service Agreement"
                to="/serviceagreement"
                icon={<GavelIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="EULA"
                to="/eula"
                icon={<DescriptionIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Contract"
                to="/contract"
                icon={<AssignmentIcon />}
                selected={selected}
                setSelected={setSelected}/>
        </Box>
    );
};

const ClientMenu = ({isCollapsed, colors, selected, setSelected}) => {
    return (
        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
                title="Dashboard"
                to="/dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}>Data</Typography>

            <Item
                title="Locations"
                to="/locations"
                icon={<HomeWorkOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>
{/** 
            <Item
                title="Measurements"
                to="/measurements"
                icon={<TimelineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Events"
                to="/events"
                icon={<InsightsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>
*/}
            {/*
            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}>Assets</Typography>

            <Item
                title="Devices"
                to="/devices"
                icon={<SensorsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Hubs"
                to="/hubs"
                icon={<SensorsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}/>
            */}

            <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}>Account</Typography>

            <Item
                title="Service Agreement"
                to="/service-agreement"
                icon={<GavelIcon />}
                selected={selected}
                setSelected={setSelected}/>

            <Item
                title="Privacy Policy"
                to="/privacy-policy"
                icon={<SecurityIcon />}
                selected={selected}
                setSelected={setSelected}/>
        </Box>
    );
};

const MySidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { isAuthenticated, isAdmin, isOrg, isClient, uid } = useAuth();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [avatar, setAvatar] = useState(undefined);

    useEffect(() => {
        if( avatar === undefined ){
            if( isAdmin() === true ){
                setAvatar(`../../assets/Logo2.png`);
            }else if( isOrg() === true ){
                setAvatar(`../../cropped-dsw-drop-192x192.png`);
            }else if( isClient() === true ){
                getUserData(uid)
                    .then( (obj) => {
                        if( obj.hasOwnProperty("clients") === true ){
                        const clientIds = Object.keys(obj.clients);
                        //console.log(clientIds);
                        if( clientIds.length > 0 && obj.clients[clientIds[0]] === true ){
                            getClientData(clientIds[0])
                                .then( (cliobj) => {
                                    if( cliobj.hasOwnProperty("avatar") === true ){
                                        setAvatar(cliobj.avatar);
                                    }
                                })
                                .catch( (err) => {
                                    //console.log(err);
                                });
                            }
                        }
                    })
                    .catch( (err) => {
                    //console.log(err);
                    });
            }
        }
    }, [avatar, setAvatar, isAdmin, isOrg, isClient, uid]);

    return (
        <Sidebar collapsed={isCollapsed} backgroundColor={'rgba(0, 0, 0, 0)'} >
            <Menu iconShape="square">
                <MenuItem
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    style={{
                        margin: "10px 0 20px 0",
                        color: colors.grey[100],
                    }}>
                    {!isCollapsed && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            ml="15px">
                            <Typography variant="h3" color={colors.grey[100]}>IoT Dashboard</Typography>
                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                <MenuOutlinedIcon />
                            </IconButton>
                        </Box>
                    )}
                </MenuItem>

                <Box mb="25px">
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <img
                            alt="profile-user"
                            width={isCollapsed ? "50px" : "100px"}
                            src={avatar}
                            style={{ cursor: "pointer", borderRadius: "50%" }}
                        />
                    </Box>
                </Box>

                {
                    isAuthenticated() && isAdmin() && (
                        <AdminMenu isCollapsed={isCollapsed} colors={colors} selected={selected} setSelected={setSelected} />
                    )
                }

                {
                    isAuthenticated() && isOrg() && (
                        <OrgMenu isCollapsed={isCollapsed} colors={colors} selected={selected} setSelected={setSelected} />
                    )
                }

                {
                    isAuthenticated() && isClient() && (
                        <ClientMenu isCollapsed={isCollapsed} colors={colors} selected={selected} setSelected={setSelected} />
                    )
                }
            </Menu>
        </Sidebar>
    );
};

export default MySidebar;