import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { useAuth } from '../../components/AuthContext';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isAdmin, isOrg } = useAuth();
  
  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Admin Dashboard" subtitle="Welcome to your dashboard" />   
            </Box>
        </Container>
    </React.Fragment>
  );
};

export default Dashboard;