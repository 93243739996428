import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {useTheme, Backdrop, CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../components/AuthContext';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.sigmatiqa.co.uk/">
        Sigmatiqa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function EmailVerify() {
    const theme = useTheme();
    const [errors, setErrors] = useState({ send: '' });
    const [statusMsg, setStatusMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { sendEmailVerify } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        sendEmailVerify()
            .then( (result) => {
              //console.log(result);
              if( result.hasOwnProperty("status") === true ){
                if( result.status === true ){
                  setStatusMsg(result.msg);
                }else{
                  let tempErrors = { send: '' };
                  tempErrors.send = result.msg;
                  setErrors(tempErrors);
                }
              }
              setLoading(false);
            })
            .catch( (err) => {
                setLoading(false);
            });
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/*<Avatar sx={{ width: 100, height: 100, m: 5, bgcolor: 'background.default' }}></Avatar> */}
          <img src='../../assets/logo2.png' alt="Logo" width="190px" />
          <Typography component="h1" variant="h5">
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: "48px" }}
            >
              Verify Email
            </Button>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  {statusMsg} <Link href="/login" variant="body1"> Return to sign-in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}