import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function SummaryCard({ title, subtitle, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      component={Stack}
      spacing={1}
      direction="row"
      height={160}
      sx={{
        px: 3,
        py: 5,
        borderRadius: 3,
        ...sx,
      }}
      {...other}
    >
      {icon && <Box sx={{ width: "64px", height: "64px" }}>{icon}</Box>}

      <Stack spacing={0.5}>
        <Typography variant="h3">{total}</Typography>

        <Typography variant="h5" sx={{ color: 'text.disabled' }}>
          {title}
        </Typography>

      { subtitle && (
        <Typography variant="h6" sx={{ color: 'text.disabled' }}>
          {subtitle}
        </Typography>
      )}
      </Stack>
    </Card>
  );
}