import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import { useAuth } from './components/AuthContext';

import RequireAuth from './components/ProtectedRoute';
import RequireAuthNoSidebar from './components/ProtectedRouteNoSidebar';

//import Topbar from "./scenes/global/Topbar";
import SignIn from "./scenes/signin/SignIn";
import ForgotPassword from "./scenes/signin/ForgotPassword";
import EmailVerify from "./scenes/signin/EmailVerify";
import Welcome from "./scenes/signin/Welcome";
import NotFound from "./scenes/global/NotFound";

import AdminDashboard from './scenes/admin/Dashboard';
import OrgDashboard from './scenes/org/Dashboard';
import ClientDashboard from './scenes/client/Dashboard';

import ClientMeasurements from './scenes/client/Measurements';

import AdminUsers from './scenes/admin/Users';
import AdminAddUser from './scenes/admin/AddUser';

import AdminOrganisations from './scenes/admin/Organisations';
import AdminAddOrganisation from './scenes/admin/AddOrganisation';
import AdminOrganisation from './scenes/admin/Organisation';
import AdminClient from './scenes/admin/Client';
import AdminLocation from './scenes/admin/Location';
import AdminDevices from './scenes/admin/Devices';
import AdminDevice from './scenes/admin/Device';
import AdminAddDevice from './scenes/admin/AddDevice';

import ClientLocations from './scenes/client/Locations';
import ClientLocation from './scenes/client/Location';
import ClientDevice from './scenes/client/Device';
import ClientServiceAgreement from './scenes/client/ServiceAgreement';
import ClientPrivacyPolicy from './scenes/client/PrivacyPolicy';

/*
import Events from './scenes/events/Events';

import Users from './scenes/users/Users';
import AddUser from './scenes/users/AddUser';


import Organisation from './scenes/organisations/Organisation';
import Client from './scenes/organisations/Client';
import Location from './scenes/organisations/Location';

import AddOrganisation from './scenes/organisations/AddOrganisation';
import AddClient from './scenes/organisations/AddClient';
import AddLocation from './scenes/organisations/AddLocation';
import AddDevice from "./scenes/devices/AddDevice";
import Device from "./scenes/devices/Device";

import Networks from "./scenes/networks/Networks";
import AddNetwork from "./scenes/networks/AddNetwork";
import Devices from "./scenes/devices/Devices";
import Rules from "./scenes/rules/Rules";
import AddRule from "./scenes/rules/AddRule";
import MachineLearning from "./scenes/machinelearning/MachineLearning";
*/


function App() {
    const { isAuthenticated, isAdmin, isClient, isOrg } = useAuth();
    const [theme, colorMode] = useMode();
    const location = useLocation();

    useEffect( ()=> {
        //console.log(location);
    },[location]);

    useEffect(() => {
        //console.log(isAuthenticated());
        //console.log(isAdmin());
        //console.log(isClient());
        //console.log(isOrg());
    }, [isAuthenticated, isAdmin, isClient, isOrg]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                        <Routes>
                            <Route path="/login" element={<SignIn />} />
                            <Route path="/logout" element={<SignIn />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/email-verify" element={<EmailVerify />} />
                            <Route 
                                path="/welcome" 
                                element={
                                    <RequireAuthNoSidebar>
                                        <Welcome/>
                                    </RequireAuthNoSidebar>
                                }
                            />
                            <Route 
                                path="/" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminDashboard/>)}
                                        { isOrg() && (<OrgDashboard/>)}
                                        { isClient() && (<ClientDashboard/>)}
                                    </RequireAuth>
                                }
                            />
                            <Route 
                                path="/dashboard" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminDashboard/>)}
                                        { isOrg() && (<OrgDashboard/>)}
                                        { isClient() && (<ClientDashboard/>)}
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/users" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminUsers/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/users/add" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminAddUser/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/organisations" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminOrganisations/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}  
                                    </RequireAuth>
                                }
                            />
                            <Route 
                                path="/organisations/add" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminAddOrganisation/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}  
                                    </RequireAuth>
                                }
                            />
                            <Route 
                                path="/organisation" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminOrganisation/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}  
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/client" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminClient/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}  
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/locations" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<NotFound/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<ClientLocations/>)}  
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/location" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminLocation/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<ClientLocation/>)}  
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/devices" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminDevices/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}
                                    </RequireAuth>
                                }
                            />
                            <Route 
                                path="/devices/add" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminAddDevice/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<NotFound/>)}
                                    </RequireAuth>
                                }
                            />
                            <Route 
                                path="/device" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<AdminDevice/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<ClientDevice/>)}  
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path="/measurements" 
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<NotFound/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<ClientMeasurements/>)}
                                    </RequireAuth>
                                }
                            />

                            <Route
                                path="/service-agreement"
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<NotFound/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<ClientServiceAgreement/>)}
                                    </RequireAuth>
                                }
                            />

                            <Route
                                path="/privacy-policy"
                                element={
                                    <RequireAuth>
                                        { isAdmin() && (<NotFound/>)}
                                        { isOrg() && (<NotFound/>)}
                                        { isClient() && (<ClientPrivacyPolicy/>)}
                                    </RequireAuth>
                                }
                            />

                            <Route 
                                path='*' 
                                exact={true}  
                                element={<NotFound/>}
                            />            
                        </Routes>
                    
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
