import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { getLocationList } from '../../data/database';
import Header from "../../components/Header";
import LocationCard from '../../components/LocationCard';
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Client = ({route}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, isOrg } = useAuth();
  const [locations, setLocations] = useState(undefined);

  const handleAddLocation = () => {
    navigate('locations/add', {state: location.state});
  };

  useEffect(() => {
    //console.log("Client Render");
    if( locations === undefined ){
        getLocationList(location.state.orgid, location.state.cliid).then( (obj) => {
            var arr = [];
            Object.keys(obj).forEach( item => {
                //console.log(obj[item]);
                arr.push(obj[item]);
            });
            setLocations(arr);
          });
    }
  });

  useEffect( () => {
    //console.log("Locations Change Render");
  }, [locations]);

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={location.state.cliname} subtitle="Manage locations for where your devices will be installed" />
                <Button
                  sx={{
                      backgroundColor: "#5562AC",
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: '#7785AC',
                        boxShadow: 'none',
                      }
                  }}
                  onClick={handleAddLocation}
                >
                    <AddIcon sx={{ mr: "10px" }} />
                    Add Location
                </Button>
            </Box>
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item sx={{height: 300}} elevation={0}>
                        <Box justifyContent="center" alignItems="center">
                            <img
                                alt="profile-user"
                                height="261px"
                                src={`../../../assets/client_not_selected.png`}
                            />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    { /* <ClientsTable /> */ }
                    <Container>
                        <Box sx={{ paddingTop: 4 }}>
                            { locations && Array.isArray(locations) && locations.length > 0 && (
                                locations.map((loc) => ( <LocationCard key={loc.id} org={location.state.orgid} client={location.state.cliid} location={loc} /> ))
                            )}
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    </React.Fragment>
  );
}

export default Client;