import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { tokens } from "../theme";
import { Box, Button, Typography, useTheme, useMediaQuery, TextField } from "@mui/material";
import ChipAdder from "./ChipAdder";
import SelectTextField from "./SelectTextField";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref as dbRef, set } from 'firebase/database';
import { getOrganisationList } from '../data/database';
import { getAllClientList } from '../data/database';
import { addUserAccess } from '../data/database';

const steps = ['Specify user name', 'Specify user credentials', 'Specify user access privileges'];

const accessList = [
  {
    value: 'client',
    name: 'Client',
  },
  {
    value: 'org',
    name: 'Organisation',
  },
  {
    value: 'admin',
    name: 'Administrator',
  }
];

const UserAddForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));
    const colors = tokens(theme.palette.mode);
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [cliList, setCliList] = useState([]);
    
    const [access, setAccess] = useState("");
    const [orgs, setOrgs] = useState([]);
    const [clis, setClis] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        access: "",
        orgs: [],
        clients: [],
        locations: []
    });

    useEffect(() => {
        getOrganisationList().then( (obj) => {
            var arr = [];
            Object.keys(obj).forEach( item => {
                arr.push(obj[item]);
            });
            setOrgList(arr);
        });

        getAllClientList().then( (obj) => {
            var arr = [];
            Object.keys(obj).forEach( item => {
                arr.push(obj[item]);
            });
            setCliList(arr);
        });
    }, []);


    const handleNext = () => {
        var currStep = activeStep;

        if( currStep + 1 === steps.length ){
            handleSubmit();
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit = async () => {
        setLoading(true);
        handleUpload()
            .then( (result) => {
                setLoading(false);
                if( result === true ){
                    navigate('/users');
                }
            })
            .catch( (err) => {
                setLoading(false);
            });
    };

    const handleUpload = async () => {
        var status = false;

        setUploading(true);
        var data = formData;
        data = {...data, access: access, orgs: orgs, clients: clis};
        setFormData(data);

        try {
            //console.log(data);

            addUserAccess(data)
                .then( () => {
                    //console.log("Updated user privilege");
                })
                .catch( () => {
                    //console.log("Failed to update user privilege");
                });

            status = true;
        } catch (error) {
            //console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
        }

        return status;
    };

    const handleChange = () => (event) => {
        const { name, value } = event.target;
        if( name === "name" ){
            setFormData((prevData) => ({
                ...prevData,
                name: value
            }));
        } else if( name === "email" ){
            setFormData((prevData) => ({
                ...prevData,
                email: value
            }));
        } else if( name === "access" ){
            setFormData((prevData) => ({
                ...prevData,
                access: value
            }));
        }
    };
  

    return (
        <React.Fragment>
            <CssBaseline />
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >Back</Button>
                </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {
                        getStepContent(activeStep, 
                                       isXs, 
                                       formData, 
                                       handleChange, 
                                       {
                                           accessList: accessList,
                                           access: access,
                                           accessSetter: setAccess,
                                           orgList: orgList, 
                                           orgsGetter: orgs, 
                                           orgSetter: setOrgs,
                                           cliList: cliList, 
                                           clisGetter: clis, 
                                           cliSetter: setClis
                                       }
                                      )
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >Back</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const getStepContent = (step, xs, formData, handleChange, config) => {
    switch (step) {
        case 0:
            return (<Step1 isXs={xs} formData={formData} handleChange={handleChange} config={config}/>);

        case 1:
            return (<Step2 isXs={xs} formData={formData} handleChange={handleChange} config={config}/>);

        case 2:
            return (<Step3 isXs={xs} formData={formData} handleChange={handleChange} config={config} />);

        default:
            return 'Unknown step';
    }
};

const Step1 = ({isXs, formData, handleChange, config}) => {
    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="left"
                                display={{ xs: 'none', md: 'block' }}
                            >
                              Name
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                          <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Name"}</Typography>
                                    <TextField
                                        required
                                        name="name"
                                        label={isXs ? "Name" : ""}
                                        fullWidth
                                        height="80px"
                                        helperText="Please enter the user's full name"
                                        onChange={handleChange()}
                                        value={formData.name} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
};

const Step2 = ({isXs, formData, handleChange, config}) => {
    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="left"
                                display={{ xs: 'none', md: 'block' }}
                            >
                              Credentials
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                          <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Email Address"}</Typography>
                                    <TextField
                                        required
                                        name="email"
                                        label={isXs ? "Email Address" : ""}
                                        fullWidth
                                        height="80px"
                                        helperText="Please enter the user email address"
                                        onChange={handleChange()}
                                        value={formData.email} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
};

const Step3 = ({isXs, formData, handleChange, config}) => {
    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="left"
                                display={{ xs: 'none', md: 'block' }}
                            >
                              Credentials
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                          <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Access"}</Typography>
                                    <SelectTextField name={"Access"} options={config.accessList} selected={config.access} setSelected={config.accessSetter}/>
                                </Box>
                                <Box sx={{marginTop: 2}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Organisation/s"}</Typography>
                                    <ChipAdder options={config.orgList} selected={config.orgsGetter} setSelected={config.orgSetter} />
                                </Box>
                                <Box sx={{marginTop: 2}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Client/s"}</Typography>
                                    <ChipAdder options={config.cliList} selected={config.clisGetter} setSelected={config.cliSetter} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
};
export default UserAddForm;