import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme, Backdrop, CircularProgress, Typography } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { getLocationDeviceList } from '../../data/database';
import Header from "../../components/Header";
import DeviceCard from '../../components/DeviceCard';
import LocationMap from '../../components/LocationMap';
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Location = ({route}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { isAdmin, isOrg } = useAuth();
  const location = useLocation();
  const [devices, setDevices] = useState(undefined);

  useEffect(() => {
    if( devices === undefined ){
      //console.log("Fetching devices for " + location.state.loc.id);
        getLocationDeviceList(location.state.loc.id)
            .then( (obj) => {
                var arr = [];
                Object.keys(obj).forEach( item => {
                    arr.push(obj[item]);
                });
                setDevices(arr);
            })
            .catch( (err) => {

            });
    }
  });

  return (
    <React.Fragment>
        <CssBaseline />
        { devices ? (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={location.state.loc.name} subtitle="Manage devices and their data" />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Container>
                  <Box sx={{ paddingTop: 4, height: "300px", margin: 2 }}>
                    <LocationMap address={location.state.loc.address}/>
                  </Box>
                </Container>
              </Grid>

              <Grid item xs={12}>
                { /* <ClientsTable /> */ }
                <Container>
                  <Box sx={{ paddingTop: 4, width: "100%" }}>
                    { devices && Array.isArray(devices) && devices.length > 0 ? 
                      (devices.map((dev) => (
                        <DeviceCard key={dev.id} 
                                    org={location.state.loc.orgid} 
                                    client={location.state.loc.cliid}
                                    location={location.state.loc}
                                    device={dev} />
                      ))) : 
                      (<Typography sx={{ paddingTop: 4, width: "100%", textAlign: "center" }} >No devices added to location</Typography>)}
                  </Box>
                </Container>
              </Grid>
            </Grid>
        </Container>
        ) : (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={devices === undefined}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )}
    </React.Fragment>
  );
}

export default Location;