const valueFormatter = (date) =>
    date.toLocaleDateString('en-GB', {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  
  
const probeReadToDegC = (val) => { 
    if( val < 100){
      return null;
    }
    
    return 1.0 / ((Math.log((((3/(val/1000))-1)*10)/10)/3435)+(1/298.15))-273.15;
};

async function fetchData(token, org, cli, loc, devId, startUtc, window) {
  try {
      //console.log(devId);
      //console.log(startUtc);
      if( devId !== undefined && devId != null && 
          (typeof devId === 'string' || devId instanceof String) ){
          if( startUtc != null && startUtc !== undefined && startUtc > 1704067200 )
          {
            //console.log(token);
              //return seriesData; 
              const response = await fetch(`/api/v1/metrics/${org}/${cli}/${loc}/${devId}?&limit=44640&from=${startUtc}&to=1719207179&period=${window}&cd=true`,{
                headers: {
                    'Authorization': 'Bearer '+token
                }}
              );
              if (!response.ok) {
                  throw new Error('Network response was not ok ' + response.statusText);
              }
              const data = await response.json();
              return data.results;
          }
          else
          {
              throw new Error('Invalid fetch data arguments');
          }
      }
      else
      {
          throw new Error('Invalid fetch data arguments');
      }
  } catch (error) {
   // console.error('There has been a problem with your fetch operation:', error);
  }
}

export function largestTriangleThreeBuckets(data, threshold) {
    var floor = Math.floor,
	abs = Math.abs;

	var data_length = data.length;
	if (threshold >= data_length || threshold === 0) {
		return data; // Nothing to do
	}

	var sampled = [],
		sampled_index = 0;

	// Bucket size. Leave room for start and end data points
	var every = (data_length - 2) / (threshold - 2);

	var a = 0,  // Initially a is the first point in the triangle
		max_area_point,
		max_area,
		area,
		next_a;

	sampled[ sampled_index++ ] = data[ a ]; // Always add the first point

	for (var i = 0; i < threshold - 2; i++) {

		// Calculate point average for next bucket (containing c)
		var avg_x = 0,
			avg_y = 0,
			avg_range_start  = floor( ( i + 1 ) * every ) + 1,
			avg_range_end    = floor( ( i + 2 ) * every ) + 1;
		avg_range_end = avg_range_end < data_length ? avg_range_end : data_length;

		var avg_range_length = avg_range_end - avg_range_start;

		for ( ; avg_range_start<avg_range_end; avg_range_start++ ) {
		  avg_x += data[ avg_range_start ][ 0 ] * 1; // * 1 enforces Number (value may be Date)
		  avg_y += data[ avg_range_start ][ 1 ] * 1;
		}
		avg_x /= avg_range_length;
		avg_y /= avg_range_length;

		// Get the range for this bucket
		var range_offs = floor( (i + 0) * every ) + 1,
			range_to   = floor( (i + 1) * every ) + 1;

		// Point a
		var point_a_x = data[ a ][ 0 ] * 1, // enforce Number (value may be Date)
			point_a_y = data[ a ][ 1 ] * 1;

		max_area = area = -1;

		for ( ; range_offs < range_to; range_offs++ ) {
			// Calculate triangle area over three buckets
			area = abs( ( point_a_x - avg_x ) * ( data[ range_offs ][ 1 ] - point_a_y ) -
						( point_a_x - data[ range_offs ][ 0 ] ) * ( avg_y - point_a_y )
					  ) * 0.5;
			if ( area > max_area ) {
				max_area = area;
				max_area_point = data[ range_offs ];
				next_a = range_offs; // Next a is this b
			}
		}

		sampled[ sampled_index++ ] = max_area_point; // Pick this point from the bucket
		a = next_a; // This a is the next a (chosen b)
	}

	sampled[ sampled_index++ ] = data[ data_length - 1 ]; // Always add last

	return sampled;
}

export const getData = async (token, org, cli, loc, devId, startUtc, window) => {
    const seriesData = await fetchData(token, org, cli, loc, devId, startUtc, window);
    var transformedData = [];
    if( seriesData ){
        transformedData = seriesData.map(item => ({
            timestamp: item.timestamp * 1000,//new Date(item.timestamp * 1000), // Transform timestamp to a readable format
            data_0: item.data_0,//data_0: Math.round(probeReadToDegC(item.data_0)),
            data_1: item.data_1,//data_1: Math.round(probeReadToDegC(item.data_1)),
            data_2: item.data_2,//data_2: Math.round(probeReadToDegC(item.data_2)),
            data_3: probeReadToDegC(item.data_3),//data_3: Math.round(probeReadToDegC(item.data_3)),
            data_4: probeReadToDegC(item.data_4),//data_4: Math.round(probeReadToDegC(item.data_4)),
            data_5: probeReadToDegC(item.data_5),//data_5: Math.round(probeReadToDegC(item.data_5)),
            data_6: probeReadToDegC(item.data_6),//data_6: Math.round(probeReadToDegC(item.data_6)),
            data_7: probeReadToDegC(item.data_7),//data_7: Math.round(probeReadToDegC(item.data_7)),
            data_8: probeReadToDegC(item.data_8),//data_8: Math.round(probeReadToDegC(item.data_8))
        }));
    }

    //console.log(transformedData);

    return transformedData;
};