// LocationCard.jsx
import React from 'react';
import { Card, CardContent, Avatar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DeviceCard = ({ org, client, location, device }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/device`, 
                 {
                    state: { 
                        orgid: org, 
                        cliid: client, 
                        locid: location.id, 
                        device: device
                    }
                });
    };

    return (
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 4, margin: 2, borderRadius: 5, cursor: 'pointer' }}
              onClick={handleCardClick}
        >
            <Avatar alt={device.alias} src={`../../../assets/darwin_thermo_1.png`} sx={{ width: 72, height: 72, marginRight: 2 }} />
            <CardContent>
                <Typography variant="h4" component="div">
                {device.alias}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                {device.id}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default DeviceCard;
