import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
//import { LineChart, LineSeries, XAxis, YAxis, Title, Tooltip } from '@mui/x-charts';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getMeasurements } from '../data/database';
import { largestTriangleThreeBuckets } from '../data/measurements';
import dayjs from 'dayjs';
import LineChart from "./LineChart";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from './AuthContext';
import { hasDataVaried, range } from '../utils/value-range';

export const MeasurementChart = ({devEui, devConfig, org, cli, loc, setStats}) => {
    const { getToken } = useAuth();
    const [loading, setLoading] = React.useState(false);
    const [xAxisData, setXAxisData] = useState([]);
    const [ydata0, setYData0] = useState([]);
    const [ydata1, setYData1] = useState([]);
    const [ydata2, setYData2] = useState([]);
    const [ydata3, setYData3] = useState([]);
    const [ydata4, setYData4] = useState([]);
    const [ydata5, setYData5] = useState([]);
    const [yData, setYData] = useState([]);
    const [view, setView] = React.useState('1d');
    const [currentDate, setCurrentDate]= useState(new Date());

    const [chartData, setChartData] = useState({
        // ...chart data
      });

    function clearGraphData() {
        setXAxisData([]);
        setYData0([]);
        setYData1([]);
        setYData2([]);
        setYData3([]);
        setYData4([]);
        setYData5([]);
        setYData([]);
    }

    async function fetchData(startUtc, window) {
        clearGraphData();
        //console.log(devEui);
        //console.log(devConfig);
        setLoading(true);
        if( devEui !== undefined && devEui != null && devEui !== '' ){
            if( devConfig.hasOwnProperty("probes") === true ){

                const enabledProbeIds = devConfig.probes
                    .filter(probe => probe.enabled)
                    .map(probe => probe.probe);
                    
                if( enabledProbeIds.length > 0 ){
                    const retData = await getMeasurements(getToken(), org, cli, loc, devEui, startUtc, window);

                    if( retData ){
                        var stats = {};
                        const timestamps = retData.map(item => item.timestamp);

                        setXAxisData(timestamps.reverse());

                        var dataPoints = [];

                        const ambient = retData.map(item => item.data_0);
                        stats["ambient"] = {
                            min: Math.min(...ambient),
                            max: Math.max(...ambient),
                        };
                        dataPoints.push({id: "Ambient", data: ambient});
                        
                        stats["probes"] = [];

                        if( enabledProbeIds.includes(1) === true ){
                            const data_0 = retData.map(item => item.data_3);
                            if( devConfig.probes[0].alias ){
                                stats["probes"].push({
                                    name: devConfig.probes[0].alias,
                                    min: Math.min(...data_0),
                                    max: Math.max(...data_0),
                                });
                            }
                            dataPoints.push({id: devConfig.probes[0].alias, data: data_0.reverse()});
                        }
                        
                        if( enabledProbeIds.includes(2) === true ){
                            const data_1 = retData.map(item => item.data_4);
                            if( devConfig.probes[1].alias ){
                                stats["probes"].push({
                                    name: devConfig.probes[1].alias,
                                    min: Math.min(...data_1),
                                    max: Math.max(...data_1),
                                });
                            }
                            dataPoints.push({id: devConfig.probes[1].alias, data: data_1.reverse()});
                        }
                        
                        if( enabledProbeIds.includes(3) === true ){
                            const data_2 = retData.map(item => item.data_5);
                            if( devConfig.probes[2].alias ){
                                stats["probes"].push({
                                    name: devConfig.probes[2].alias,
                                    min: Math.min(...data_2),
                                    max: Math.max(...data_2),
                                });
                            }
                            dataPoints.push({id: devConfig.probes[2].alias, data: data_2.reverse()});
                        }

                        if( enabledProbeIds.includes(4) === true ){
                            const data_3 = retData.map(item => item.data_6);
                            if( devConfig.probes[3].alias ){
                                stats["probes"].push({
                                    name: devConfig.probes[3].alias,
                                    min: Math.min(...data_3),
                                    max: Math.max(...data_3),
                                });
                            }
                            dataPoints.push({id: devConfig.probes[3].alias, data: data_3.reverse()});
                        }

                        if( enabledProbeIds.includes(5) === true ){
                            const data_4 = retData.map(item => item.data_7);
                            if( devConfig.probes[4].alias ){
                                stats["probes"].push({
                                    name: devConfig.probes[4].alias,
                                    min: Math.min(...data_4),
                                    max: Math.max(...data_4),
                                });
                            }
                            dataPoints.push({id: devConfig.probes[4].alias, data: data_4.reverse()});
                        }

                        if( enabledProbeIds.includes(6) === true ){
                            const data_5 = retData.map(item => item.data_8);
                            if( devConfig.probes[5].alias ){
                                stats["probes"].push({
                                    name: devConfig.probes[5].alias,
                                    min: Math.min(...data_5),
                                    max: Math.max(...data_5),
                                });
                            }
                            dataPoints.push({id: devConfig.probes[5].alias, data: data_5.reverse()});
                        }

                        setStats(stats);

                        setYData(dataPoints);
                    }
                }
            }
        }else{
            //console.log("Invalid device ID");
        }
        setLoading(false);
    }

    const handleDateChange = (data) => {
        try {
            const dateObject = dayjs(data).toDate();
            setCurrentDate(dateObject);

            // Get the UTC time in milliseconds and then convert to seconds
            const utcSeconds = Math.floor(dateObject.getTime() / 1000);
            fetchData(utcSeconds, view);
        } catch (error) {

        }
    };
    
    const handlePeriodChange = (period) => {
        try {
            if( period === '1d' || period === '2d' || period === '1w' || period === '1m' ){
                setView(period);

                const date = currentDate;
                if( date && (date instanceof Date) ){
                    const utcSeconds = Math.floor(date.getTime() / 1000);
                    fetchData(utcSeconds, period);
                }
            }
        } catch (error) {

        }
    };

    const valueFormatter = (date) =>
        date.toLocaleDateString('en-GB', {
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });

    return (<Box backgroundColor='#ffffff' borderRadius={2} marginBottom={4}>
                <Box display="flex" justifyContent="space-between" p={2} backgroundColor='#ffffff' borderRadius={2}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    { (loading === false && xAxisData && xAxisData.length > 0) ?
                        <LineChart xAxisData={xAxisData} yAxisData={yData}/>
                        :
                        <Box
                            sx={{
                                height: '350px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                            <Typography textAlign={"center"}>
                                No measurement data to display. <br/>Select another date.
                            </Typography>
                        </Box>
                    }
                </Box>
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: '#ffffff00',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        border: (theme) => `0px solid #aaaaaa`,
                        flexWrap: 'wrap'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                format="DD-MM-YYYY"
                                label="Start Date"
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newValue) => handleDateChange(newValue)}
                                textField={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <ToggleButtonGroup
                        value={view}
                        exclusive
                        aria-label="text alignment"
                        spacing={3}
                        size="small"
                        sx={{margin: 0.5, border: 0, borderRadius: 0}}
                        >
                            <ToggleButton sx={{marginRight: 1, border: 0, borderRadius: 0}} value="1d" onClick={() => handlePeriodChange('1d')}>1d</ToggleButton>
                            <ToggleButton sx={{marginRight: 1, border: 0, borderRadius: 0}} value="2d" onClick={() => handlePeriodChange('2d')}>2d</ToggleButton>
                            <ToggleButton sx={{marginRight: 1, border: 0, borderRadius: 0}} value="1w" onClick={() => handlePeriodChange('1w')}>1w</ToggleButton>
                            <ToggleButton sx={{marginRight: 1, border: 0, borderRadius: 0}} value="1m" onClick={() => handlePeriodChange('1m')}>1m</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Paper>
            </Box>);
};