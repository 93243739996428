import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { tokens } from "../../theme";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import {MeasurementChart} from '../../components/MeasurementChart';
import BatteryHealthCard from '../../components/BatteryHealthCard';
import SummaryCard from '../../components/SummaryCard';
import { useAuth } from '../../components/AuthContext';
import DeviceWarnings from '../../components/client/DeviceWarnings';

import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery20Icon from '@mui/icons-material/Battery30';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import InsightsIcon from '@mui/icons-material/Insights';

const Device = ({route}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const [stats, setStats] = useState(0);
  const { isAdmin, isOrg } = useAuth();
  const [ambientMin, setAmbientMin] = useState(0);
  const [ambientMax, setAmbientMax] = useState(0);
  const [warnings, setWarnings] = useState([]);
  const [warningCount, setWarningCount] = useState(0);
  const [hotFlowMin, setHotFlowMin] = useState(0);
  const [hotFlowMax, setHotFlowMax] = useState(0);
  const [hotReturnMin, setHotReturnMin] = useState(0);
  const [hotReturnMax, setHotReturnMax] = useState(0);
  const [coldMin, setColdMin] = useState(0);
  const [coldMax, setColdMax] = useState(0);

  useEffect( () => {
    //console.log(stats);
    var w = [];
    setHotFlowMin(0);
    setHotFlowMax(0);
    setHotReturnMin(0);
    setHotReturnMax(0);
    setColdMin(0);
    setColdMax(0);

    if( stats.hasOwnProperty("ambient") === true ){
        if( stats.ambient.hasOwnProperty("min") === true ){
            setAmbientMin(stats.ambient.min);
            if( stats.ambient.max < 20 ){
                w.push({
                    type: "NOTICE",
                    msg: "Ambient temperature went lower than 10°C" 
                });
            }
        }

        if( stats.ambient.hasOwnProperty("max") === true ){
            setAmbientMax(stats.ambient.max);

            if( stats.ambient.max > 40 ){
                w.push({
                    type: "NOTICE",
                    msg: "Ambient temperature went higher than 40°C" 
                });
            }
        }

        if( stats.hasOwnProperty("probes") === true ){
            stats.probes.forEach(element => {
                if( element && element.name === "Hot Return" ){
                    if( element.hasOwnProperty("max") === true ){
                        if( element.max === Number.POSITIVE_INFINITY || element.max === Number.NEGATIVE_INFINITY ){
                            element.max = 0;
                        }
                        setHotReturnMax(Number(element.max).toFixed(1));
                    }

                    if( element.hasOwnProperty("min") === true ){
                        if( element.min === Number.POSITIVE_INFINITY || element.min === Number.NEGATIVE_INFINITY ){
                            element.min = 0;
                        }
                        setHotReturnMin(Number(element.min).toFixed(1));
                    }
                }

                if( element && element.name === "Hot Flow" ){
                    if( element.hasOwnProperty("max") === true ){
                        if( element.max === Number.POSITIVE_INFINITY || element.max === Number.NEGATIVE_INFINITY ){
                            element.max = 0;
                        }
                        setHotFlowMax(Number(element.max).toFixed(1));
                    }

                    if( element.hasOwnProperty("min") === true ){
                        if( element.min === Number.POSITIVE_INFINITY || element.min === Number.NEGATIVE_INFINITY ){
                            element.min = 0;
                        }
                        setHotFlowMin(Number(element.min).toFixed(1));
                    }
                }

                if( element && element.name === "Cold" ){
                    if( element.hasOwnProperty("max") === true ){
                        if( element.max === Number.POSITIVE_INFINITY || element.max === Number.NEGATIVE_INFINITY ){
                            element.max = 0;
                        }
                        setColdMax(Number(element.max).toFixed(1));
                    }

                    if( element.hasOwnProperty("min") === true ){
                        if( element.min === Number.POSITIVE_INFINITY || element.min === Number.NEGATIVE_INFINITY ){
                            element.min = 0;
                        }
                        setColdMin(Number(element.min).toFixed(1));
                    }
                }

                if( element && element.name === "Hot Return" && (element.max - element.min) < 2 ){
                    w.push({
                        type: "WARNING",
                        msg: "Hot return temperature has not varied by 2°C. Likely to have no flow in selected period." 
                    });
                }

                if( element && element.name === "Hot Flow" && (element.max - element.min) < 2 ){
                    w.push({
                        type: "WARNING",
                        msg: "Hot flow temperature has not varied by 2°C. Likely to have no flow in selected period." 
                    });
                }

                if( element && element.name === "Cold" && (element.max - element.min) < 2 ){
                    w.push({
                        type: "WARNING",
                        msg: "Cold temperature has not varied by 2°C. Likely to have no flow in selected period." 
                    });
                }
            });
        }

        if( stats.hasOwnProperty("probes") === true ){
            stats.probes.forEach(element => {
                if( element && element.name === "Hot Return" && element.max > 60 ){
                    w.push({
                        type: "WARNING",
                        msg: "Hot return temperature went higher than 60°C. Potential scalding risk." 
                    });
                }
            });
        }

        if( stats.hasOwnProperty("probes") === true ){
            stats.probes.forEach(element => {
                if( element && element.name === "Hot Flow" && element.max > 60 ){
                    w.push({
                        type: "WARNING",
                        msg: "Hot flow temperature went higher than 60°C. Potential scalding risk." 
                    });
                }
            });
        }
        //console.log(stats);
    }
    setWarningCount(w.length);
    setWarnings(w);
  }, [stats]);

  return (
    <React.Fragment>
        <CssBaseline />
        { location.state.device && (
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={location.state.device.alias} subtitle="" />
            </Box>
            <Grid container spacing={3} sx={{mb: 4}}>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <SummaryCard
                        title="Warnings"
                        total={warningCount}
                        color="success"
                        icon={<WarningAmberIcon sx={{ fontSize: 40, color: "#db9b35" }}/>}
                        subtitle="Potential issues"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <SummaryCard
                        title="Hot Flow Min/Max"
                        total={hotFlowMin + "°C / " + hotFlowMax + "°C"}
                        color="success"
                        icon={<ThermostatIcon sx={{ fontSize: 40, color: "#d90404" }}/>}
                        subtitle="Min/max temperature"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <SummaryCard
                        title="Hot Return Min/Max"
                        total={hotReturnMin + "°C / " + hotReturnMax + "°C"}
                        color="success"
                        icon={<ThermostatIcon sx={{ fontSize: 40, color: "#42f572" }}/>}
                        subtitle="Min/max temperature"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <SummaryCard
                        title="Cold Min/Max"
                        total={coldMin + "°C / " + coldMax + "°C"}
                        color="success"
                        icon={<ThermostatIcon sx={{ fontSize: 40, color: "#0077e6" }}/>}
                        subtitle="Min/max temperature"
                    />
                </Grid>
            </Grid>

            <MeasurementChart devEui={location.state.device.id.toLowerCase()} 
                              devConfig={location.state.device.config} 
                              org={location.state.orgid} 
                              cli={location.state.cliid} 
                              loc={location.state.locid}
                              setStats={setStats}/>

            <Grid container spacing={3} sx={{mb: 12}}>
                <Grid item xs={12}>
                    <DeviceWarnings data={warnings} />
                </Grid>
            </Grid>
        </Container>
        )}
    </React.Fragment>
  );
};

export default Device;