import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme, Backdrop, CircularProgress } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { getUserData, getClientData, getLocationList } from '../../data/database';
import Header from "../../components/Header";
import LocationCard from '../../components/LocationCard';
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Locations = ({route}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, isOrg, uid } = useAuth();
  const [orgId, setOrgId] = useState(undefined);
  const [cliId, setCliId] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [locations, setLocations] = useState(undefined);

  useEffect(() => {
    //console.log("Client Render");
    if( locations === undefined ){
        getUserData(uid)
            .then( (obj) => {
                //console.log(obj);
                if( obj.hasOwnProperty("orgs") === true ){
                  const orgIds = Object.keys(obj.orgs);
                  //console.log(orgIds);
                  if( orgIds.length > 0 && obj.orgs[orgIds[0]] === true ){
                      setOrgId(orgIds[0]);
                  }
                }

                if( obj.hasOwnProperty("clients") === true ){
                  const clientIds = Object.keys(obj.clients);
                  //console.log(clientIds);
                  if( clientIds.length > 0 && obj.clients[clientIds[0]] === true ){
                      setCliId(clientIds[0]);
                  }
                }
            })
            .catch( (err) => {
              //console.log(err);
            });
    }
  });

  useEffect( () => {
    //console.log("Organisation retrieved");
  },[orgId]);

  useEffect( () => {
    //console.log("Client retrieved");
    getClientData(cliId)
        .then( (obj) => {
            setClient(obj);
        })
        .catch( (err) => {
          //console.log(err);
        });
  },[cliId]);

  useEffect( () => {
      //console.log("Fetching locations...");
      getLocationList(orgId, cliId).then( (obj) => {
          var arr = [];
          Object.keys(obj).forEach( item => {
              //console.log(obj[item]);
              arr.push(obj[item]);
          });
          setLocations(arr);
      });
  }, [client, orgId, cliId]);

  useEffect( () => {
    //console.log("Locations Change Render");
  }, [locations]);

  return (
    <React.Fragment>
        <CssBaseline />
        {(orgId && cliId && client && client.hasOwnProperty("name") ) ? (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={client.name} subtitle="Manage locations for where your devices will be installed" />
            </Box>
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item sx={{height: 300}} elevation={0}>
                        <Box justifyContent="center" alignItems="center">
                            <img
                                alt="profile-user"
                                height="261px"
                                src={`../../../assets/client_not_selected.png`}
                            />
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    { /* <ClientsTable /> */ }
                    <Container>
                        <Box sx={{ paddingTop: 4 }}>
                            { locations && Array.isArray(locations) && locations.length > 0 && (
                                locations.map((loc) => ( <LocationCard key={loc.id} org={orgId} client={cliId} location={loc} /> ))
                            )}
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </Container>
        ) : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={orgId === undefined}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
    </React.Fragment>
  );
}

export default Locations;