import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Grid, useTheme, Box } from '@mui/material';
import { ColorModeContext, tokens } from "../theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export const OrganisationData = ({ data }) => {

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      padding={2}
    >
      <img
        alt="network-img"
        height="140px"
        src={data.avatar}
      />
      <Grid container spacing={0} justifyContent="center" maxWidth="600px" marginTop={1}>
        { data && data.info && data.info.pocname && data.info.pocname !== "" && (
          <Grid container item>
              <Grid item xs={6}>
                <Typography align="right" marginRight={1} fontWeight="bold">
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left" marginLeft={1}>{data.info.pocname}</Typography>
              </Grid>
          </Grid>
        )}

        { data && data.info && data.info.poctel && data.info.poctel !== "" && (
          <Grid container item>
              <Grid item xs={6}>
                <Typography align="right" marginRight={1} fontWeight="bold">
                  Telephone
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left" marginLeft={1}>{data.info.poctel}</Typography>
              </Grid>
          </Grid>
        )}

        { data && data.info && data.info.pocemail && data.info.pocemail !== "" && (
          <Grid container item>
              <Grid item xs={6}>
                <Typography align="right" marginRight={1} fontWeight="bold">
                  Email Address
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="left" marginLeft={1}>{data.info.pocemail}</Typography>
              </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrganisationData;