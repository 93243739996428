// src/Notifications.js
import React, { useState } from 'react';
import { 
    Card, 
    CardContent, 
    TextField, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TablePagination,
    Typography
} from '@mui/material';

const Notifications = ({ data }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0); // Reset to first page on search
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page on rows per page change
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some(
            (value) =>
                value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        )
    );

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" sx={{ color: 'text.disabled' }}>
                Notifications
                </Typography>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                    margin="normal"
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Device</TableCell>
                                <TableCell>Trigger</TableCell>
                                <TableCell>Severity</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>When</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={index} style={{ height: 40 }}>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.device}</TableCell>
                                        <TableCell>{row.trigger}</TableCell>
                                        <TableCell>{row.severity}</TableCell>
                                        <TableCell>{row.msg}</TableCell>
                                        <TableCell>{row.when}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CardContent>
        </Card>
    );
};

export default Notifications;
