import React, {useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Contract from '../../components/client/Contract';
import { useAuth } from '../../components/AuthContext';
import { getClientPrivacyPolicy } from '../../data/database';

const PrivacyPolicy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [policyText, setPolicyText] = useState(undefined);
  const {uid} = useAuth();

  useEffect(() => {
    if( policyText === undefined ){
        getClientPrivacyPolicy(uid)
            .then( (privacyPolicyText) => {
              if( privacyPolicyText !== "" && privacyPolicyText !== undefined && privacyPolicyText !== null ){
                  const formattedText = privacyPolicyText.replace(/\\n/g, '\n');
                  setPolicyText(formattedText);
              }
            })
            .catch( (err) => {
              //console.log(err);
            });
    }
  });

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Privacy Policy" subtitle="Privacy policy for how Sigmatiqa Ltd handles your data" />
            </Box>
            <Contract title={"Privacy Policy"} text={policyText} />
        </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicy;