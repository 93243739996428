import React, { useContext } from 'react';
import { Navigate, useLocation } from "react-router";
import MySidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";
import { useAuth } from './AuthContext';

const RequireAuth = ({ children }) => {
    const { isAuthenticated } = useAuth();
    let location = useLocation();

    //console.log("Checking auth: " + isAuthenticated());
    if( isAuthenticated() === false ) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return (
        <>
            <MySidebar />
            <main className="content">
            <Topbar />
            {children}
            </main>
        </>);
};

export default RequireAuth;