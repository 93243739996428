// LocationCard.jsx
import React from 'react';
import { Card, CardContent, Avatar, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const LocationCard = ({ org, client, location }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/location`, {state: {loc: location}});
    };

    const buildAddress = () => {
        var address = "";
        if( location.address ){
            if( location.address.addrline1 && location.address.addrline1 !== "" ){
                address += location.address.addrline1;
            }
    
            if( location.address.addrline2 && location.address.addrline2 !== "" ){
                address += ", " + location.address.addrline2;
            }
    
            if( location.address.towncity && location.address.towncity !== "" ){
                address += ", " + location.address.towncity;
            }

            if( location.address.county && location.address.county !== "" ){
                address += ", " + location.address.county;
            }

            if( location.address.postcode && location.address.postcode !== "" ){
                address += ", " + location.address.postcode;
            }

            if( location.address.country && location.address.country !== "" ){
                address += ", " + location.address.country;
            }
        }

        return address;
    };

    return (
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 4, margin: 2, borderRadius: 5, cursor: 'pointer' }}
              onClick={handleCardClick}
        >
            <Avatar alt={location.name} sx={{ bgcolor: "#00aa00", width: 72, height: 72, marginRight: 2 }}><LocationOnOutlinedIcon sx={{ width: 36, height: 36 }}/></Avatar>
            <CardContent>
                <Typography variant="h4" component="div">
                {location.name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                {buildAddress()}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default LocationCard;
