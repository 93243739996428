import React from 'react';
import { Select, MenuItem, Box, Autocomplete, useTheme, useMediaQuery } from '@mui/material';

const SelectTextField = ({name, options, selected, setSelected}) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));

    const handleSelected = (event) => {
        setSelected(event.target.value);
    };

    return (
        <Box sx={{width: "100%"}}>
            <Select
              sx={{width: "100%"}}
              value={selected}
              label={isXs ? {name} : ""}
              onChange={handleSelected}
            >
                {options.map((item) => (
                  <MenuItem key={item.name} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default SelectTextField;
