import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, RecaptchaVerifier, onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [privileges, setPrivileges] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uid, setUid] = useState(null);
    const [bearerToken, setBearerToken] = useState("");

    const access = useRef("");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
            if (user) {
                setCurrentUser(user);

                //! Get user email and verification status
                if( user.hasOwnProperty('email') === true ) {
                    if( user.email ){
                        if( user.hasOwnProperty('emailVerified') === true && user.emailVerified === true ) {
                                const newToken = await user.getIdToken(true);
                                const idTokenResult = await user.getIdTokenResult();
                                if( idTokenResult.hasOwnProperty("claims") === true && idTokenResult.claims.hasOwnProperty("privileges") === true ){
                                    access.current = idTokenResult.claims.privileges;
                                    setBearerToken(newToken);
                                    setPrivileges(idTokenResult.claims.privileges || null);
                                    setUid(user.uid);
                                    navigate('/dashboard');
                                }else{
                                    //throw new Error({error: {code: "Failed to get user token", message:"Check internet connection and try again."}});
                                }
                        }else{
                            navigate('/email-verify');
                        }
                    }else{
                        //throw new Error({error: {code: "User does not have an email", message:"Invalid user email"}});
                        navigate('/logout');
                    }
                }else{
                    navigate('/logout');
                }
            } else {
                setPrivileges(null);
                setUid(null);
                setCurrentUser(null);
                setBearerToken("");
            }
          
            setLoading(false);
        });
    
        return unsubscribe;
      }, []);

    useEffect( ()=> {
        //console.log("Changed access " + access.current);
    }, [access]);

    const intervalId = setInterval(checkLoginExpiry, 15 * 60 * 1000); // Check every 60 seconds

    function checkLoginExpiry() {
        const thisUser = getAuth().currentUser;
        if( thisUser && 
            thisUser.hasOwnProperty("metadata") === true && 
            thisUser.metadata.hasOwnProperty("lastSignInTime") === true ){
            const lastSignInTime = new Date(thisUser.metadata.lastSignInTime).getTime();
            const currentTime = new Date().getTime();
            if (currentTime - lastSignInTime > 60 * 60 * 1000 ) {
                getAuth().signOut();
            }
        }
    }

    const getToken = () => { return bearerToken; }
    const isAuthenticated = () => !!currentUser;
    const isAdmin = () => privileges === 'admin';
    const isOrg = () => privileges === 'org';
    const isClient = () => privileges === 'client';

    const login = async (email, password) => {
        try{
            const user = await signInWithEmailAndPassword(getAuth(), email, password)
                    .then((userCredential) => {
                        // Signed in 
                        const user = userCredential.user;
                        //console.log(user);
                        return user;
                    })
                    .catch((error) => {
                        //const errorCode = error.error.code;
                        const errorMessage = error.message;
                        //console.error(error);
                        throw new Error(errorMessage);
                    });

            

            //! Set User ID
            if( user ){
                if( user.hasOwnProperty('uid') === true ){
                        //setUid(getAuth().currentUser.uid);
                    return true;
                }
            }
        }catch(error){
            //console.error(error);
            throw new Error("Login error. Check your username and password and try again.");
        }

        return false;
    };

    const logout = async () => {
        getAuth().signOut();
    };

    const loginWithRecaptcha = async (email, password) => {
        const recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            size: 'invisible',
            callback: (response) => {
                
            }, 
        }, getAuth());

        recaptchaVerifier.render().then(() => {
            //Create a state called recaptchaLoaded, setRecaptchaLoaded
            //setRecaptchaLoaded(true);
        });
    };

    const sendPaswordReset = async (email) => {
        const prom = sendPasswordResetEmail(getAuth(), email);
        const result = await prom.then(() => {
                            return {status: true, code: "", msg: "Password reset email sent. Check your email and follow the instructions to reset your password. "};
                        })
                        .catch((error) => {
                            return {status: false, code: error.code, msg: "Password reset email failed. Check email address and internet connection and try again."};
                        });
        return result;
    };

    const sendEmailVerify = async () => {
        const prom = sendEmailVerification(getAuth().currentUser);
        const result = await prom.then(() => {
                            const emailAddr = getAuth().currentUser.email;
                            return {status: true, code: "", msg: `Verification email sent to ${emailAddr}.`};
                        })
                        .catch((error) => {
                            return {status: false, code: error.code, msg: "Failed to send verification email. Check internet connection and try again."};
                        });
        return result;
    };

    const value = {
        currentUser,
        login,
        logout,
        isAuthenticated,
        sendPaswordReset,
        sendEmailVerify,
        isAdmin,
        isOrg,
        isClient,
        loading,
        uid,
        getToken
      };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};