import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import OrganisationCard from '../../components/OrganisationCard';
import { getOrganisationList } from '../../data/database';
import Header from "../../components/Header";
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Organisations = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const { isAdmin, isOrg } = useAuth();
    const [organisations, setOrganisations] = useState([]);

    const handleAddOrganisation = () => {
        navigate('/organisations/add', {state: {orgs: organisations.map(a => a.name.toLowerCase())}});
    };

    useEffect(() => {
        getOrganisationList().then( (obj) => {
            var arr = [];
            Object.keys(obj).forEach( item => {
                arr.push(obj[item]);
            });
            setOrganisations(arr);
        }); 
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Organisations" subtitle="Manage organisations" />
                    <Button
                        sx={{
                            backgroundColor: "#5562AC",
                            color: colors.grey[900],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            borderRadius: 10,
                            '&:hover': {
                                backgroundColor: '#7785AC',
                                boxShadow: 'none',
                            }
                        }}
                        onClick={handleAddOrganisation}
                    >
                        <AddIcon sx={{ mr: "10px" }} />
                        Add Organisation
                    </Button>
                </Box>
            
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item sx={{height: 300}} elevation={0}>
                            <Box justifyContent="center" alignItems="center">
                            <img
                                alt="profile-user"
                                height="261px"
                                src={`../../../assets/client_not_selected.png`}
                            />
                            </Box>
                        </Item>
                    </Grid>

                    <Grid item xs={12}>
                        { /* <ClientsTable /> */ }
                        <Container>
                        <Box sx={{ paddingTop: 4 }}>
                            {organisations.map((org) => (
                            <OrganisationCard key={org.id} org={org} />
                            ))}
                        </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default Organisations;