import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, useTheme, useMediaQuery, Backdrop, CircularProgress } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import {OrganisationData} from '../../components/OrganisationData'
import { getOrganisation, getClientList } from '../../data/database';
import Header from "../../components/Header";
import ClientCard from '../../components/ClientCard';
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Organisation = ({route}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, isOrg } = useAuth();
  const [org, setOrg] = useState(undefined);
  const [clients, setClients] = useState([]);

  const handleAddClient = () => {
    location.state = {...location.state, clients:clients.map(a=>a.name.toLowerCase())};
    navigate('clients/add', {state: location.state});
  };

  useEffect(() => {
    //console.log("Fetching organisation for org ID " + location.state.orgid);
    getOrganisation(location.state.orgid)
        .then( (obj) => {
            if( obj ){
                //console.log(obj);
                setOrg(obj);
            }
        })
        .catch( (err) => {

        });
  }, []);

  useEffect( () => {
    if( org && org.hasOwnProperty("id") ){
        getClientList(org.id).then( (obj) => {
            var arr = [];
            Object.keys(obj).forEach( item => {
                arr.push(obj[item]);
            });
            setClients(arr);
        });
    }
  }, [org]);

  return (
    <React.Fragment>
        <CssBaseline />
        {org ? (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={org.name} subtitle="Manage clients and locations to assign devices to" />
                <Button
                  sx={{
                      backgroundColor: "#5562AC",
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: '#7785AC',
                        boxShadow: 'none',
                      }
                  }}
                  onClick={handleAddClient}
                >
                    <AddIcon sx={{ mr: "10px" }} />
                    Add Client
                </Button>
            </Box>
            
            <Grid container spacing={2}>
              { !isXs && (
                <Grid item xs={4}>
                  <Item sx={{height: 300}} elevation={0}>
                  <Box justifyContent="center" alignItems="center">
                        <img
                          alt="profile-user"
                          height="261px"
                          src={`../../../assets/client_not_selected.png`}
                        />
                      </Box>
                  </Item>
                </Grid>
              )}

              <Grid item xs={12} md={8}>
                <Item sx={{height: 300}} elevation={0}>
                  <Box justifyContent="center" alignItems="center" height="298px" width="100%" sx={{backgroundColor: '#ffffff00', display: 'flex'}}>
                    <OrganisationData data={org} />
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={12}>
                { /* <ClientsTable /> */ }
                <Container>
                  <Box sx={{ paddingTop: 4 }}>
                    { clients && Array.isArray(clients) && clients.length > 0 && (
                        clients.map((client) => ( <ClientCard key={client.id} org={org.id} client={client} /> ))
                    )}
                  </Box>
                </Container>
              </Grid>
            </Grid>
        </Container>
        ) : (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={org === undefined}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
    </React.Fragment>
  );
}

export default Organisation;