import React, { useState } from 'react';
import { Button, Avatar, Typography, Container, Box } from '@mui/material';

const AvatarUpload = ({ selectedImage, setSelectedImage, position, setPosition }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setPosition({ x: 0, y: 0 }); // Reset position
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setOffset({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      setPosition({
        x: event.clientX - offset.x,
        y: event.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Container onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <Typography variant="h6" gutterBottom>
        Select an Avatar
      </Typography>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="avatar-upload"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="avatar-upload">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
      {selectedImage && (
        <Box
          mt={2}
          display="flex"
          justifyContent="center"
          position="relative"
          width={248}
          height={248}
          borderRadius="50%"
          overflow="hidden"
          border="2px solid #000"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <img
            src={selectedImage}
            alt="Avatar"
            style={{
              position: 'absolute',
              top: `${position.y}px`,
              left: `${position.x}px`,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
            draggable="false"
          />
        </Box>
      )}
    </Container>
  );
};

export default AvatarUpload;
