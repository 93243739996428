import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Grid, useTheme, Box, Container } from '@mui/material';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
  } from '@mui/x-data-grid-generator';

export const MeasurementSelectTable = ({setDevEui}) => {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const columns = [
        { field: 'location',    headerName: 'Location',    width: 180, align: 'left', headerAlign: 'left', editable: true },
        { field: 'devicetype',  headerName: 'Device Type', width: 180, align: 'left', headerAlign: 'left', editable: true },
        { field: 'devicealias', headerName: 'Device Name', flex: 1,    align: 'left', headerAlign: 'left', editable: true }
    ];

    useEffect(() => {
        setDevEui("ac1f09fffe100a35");
    }, [setDevEui]);

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                backgroundColor: '#ffffff',
                borderRadius: 2
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                checkboxSelection
            />
        </Box>
    );
};

export default MeasurementSelectTable;