import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography, useTheme, useMediaQuery, TextField } from "@mui/material";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AvatarUpload from './ImageSelect';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, set } from 'firebase/database';
import Snackbar from '@mui/material/Snackbar';
import {firebaseStorageErrorCodes} from '../data/database';

const steps = ['Specify organisation name', 'Specify organisation address', 'Specify organisation image'];

const OrganisationAddForm = ({route}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const isXs = useMediaQuery(theme.breakpoints.down('md'));
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [statusMsg, setStatusMsg] = useState("");
    const [statusMsgOpen, setStatusMsgOpen] = useState(false);
    const [avatarPosition, setAvatarPosition] = useState({ x: 0, y: 0 });
    const [errors, setErrors] = useState({name: '', info: '', avatar: ''});

    const [formData, setFormData] = useState({
      id: uuidv4(),
      name: "",
      info: {
        pocname: "",
        poctel: "",
        pocemail: "",
        address: {
            addrline1: "",
            addrline2: "",
            towncity: "",
            county: "",
            postcode: "",
            country: ""
          }
      },
      avatar: "",
      avatarpos: avatarPosition
    });
  
    const handleSubmit = async () => {
      setLoading(true);
      handleUpload()
        .then( (result) => {
            //console.log(formData);
            setLoading(false);
            if( result === true ){
                navigate('/organisations');
            }
        })
        .catch( (err) => {
            //console.log(err);
            setLoading(false);
        });
    };
  
    const validateForm = (step) => {
        var tempErrors = {name: ""};
        var valid = false;
        switch(step){
            case 0:
                //console.log(location.state.orgs);
                if (!formData.name) {
                    tempErrors.name = 'Organisation name is required';
                    valid = false;
                }else if(location.state.orgs.includes(formData.name.toLowerCase()) ) {
                    tempErrors.name = 'Organisation name already exists';
                    valid = false;
                }else{
                    valid = true;
                }
                break;

            case 1:
            case 2:
                valid = true;
                break;

            default:
                break;
        }
        setErrors(tempErrors);
        return valid;
    };

    const handleNext = () => {
      var currStep = activeStep;

      if( validateForm(currStep) ){
        if( currStep + 1 === steps.length ){
          handleSubmit();
        }else{
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      //console.log(errors);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    useEffect(() => {
        //console.log('Errors updated:', errors);
    }, [errors]);
  
    const handleChange = () => (event) => {
        const { name, value } = event.target;
        if( name === "name" ){
            setFormData((prevData) => ({
                ...prevData,
                name: value
            }));
            setErrors({
                ...errors,
                name: ''
            });
        }else if( name === "avatar" ){
            setFormData((prevData) => ({
                ...prevData,
                avatar: value
            }));
            setErrors({
                ...errors,
                avatar: ''
            });
        }else if( name === "pocname" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    pocname: value
                }
            }));
            setErrors({
                ...errors,
                info: ''
            });
        }else if( name === "poctel" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    poctel: value
                }
            }));
        }else if( name === "pocemail" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    pocemail: value
                }
            }));
        }else if( name === "addrline1" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    address: {
                        ...prevData["info"]["address"],
                        addrline1: value
                    }
                }
            }));
        }else if( name === "addrline2" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    address: {
                        ...prevData["info"]["address"],
                        addrline2: value
                    }
                }
            }));
        }else if( name === "towncity" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    address: {
                        ...prevData["info"]["address"],
                        towncity: value
                    }
                }
            }));
        }else if( name === "county" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    address: {
                        ...prevData["info"]["address"],
                        county: value
                    }
                }
            }));
        }else if( name === "postcode" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    address: {
                        ...prevData["info"]["address"],
                        postcode: value
                    }
                }
            }));
        }else if( name === "country" ){
            setFormData((prevData) => ({
                ...prevData,
                info: {
                    ...prevData["info"],
                    address: {
                        ...prevData["info"]["address"],
                        country: value
                    }
                }
            }));
        }
    };

    const handleErrors = (err) => {
        //console.log(err);
        var found = false;
        firebaseStorageErrorCodes.forEach( (value) => {
            if( err.includes(value.code) === true || err === value.code ){
                setStatusMsg(value.desc);
                setStatusMsgOpen(true);
                found = true;
            }
        });

        if( found === false ){
            setStatusMsg(err);
            setStatusMsgOpen(true);
        }
    };

    

    const handleUpload = async () => {
        var status = false;

        //console.log(avatarPosition);
        var errorMsg = "";
        if (!selectedImage) {
            handleErrors("No image selected");
            return status;
        }
    
        setUploading(true);
        setFormData((prevData) => ({
            ...prevData,
            avatarpos: avatarPosition
        }));
        var data = formData;
        data.avatarpos = avatarPosition;
        //console.log(data);
        const imageName = `avatars/${data.id}.png`;
        const storageRef = ref(getStorage(), imageName);
    
        try {
            await uploadString(storageRef, selectedImage, 'data_url');
            const downloadURL = await getDownloadURL(storageRef);
            data.avatar = downloadURL;

            if( downloadURL ){
                const dbRefPath = dbRef(getDatabase(), `darwin/udb/organisations/list/${data.id}`);
                await set(dbRefPath, data);
                status = true;
            }else{
                errorMsg = "Failed to upload image";
            }
        } catch (error) {
            errorMsg = error.code;
        } finally {
          setUploading(false);
        }

        if( status === false ){
            handleErrors(errorMsg);
        }
        
        return status;
    };

    const handleClose = (event, reason) => {
        setStatusMsgOpen(false);
    };
  
    return (
      <React.Fragment>
          <CssBaseline />
          <Snackbar
                open={statusMsgOpen}
                autoHideDuration={3000}
                message={statusMsg}
                onClose={handleClose}
          />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                      <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                  );
              })}
          </Stepper>
          {activeStep === steps.length ? (
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                      >Back</Button>
                </Box>
              </React.Fragment>
          ) : (
              <React.Fragment>
                  {getStepContent(activeStep, isXs, formData, errors, handleChange, selectedImage, setSelectedImage, avatarPosition, setAvatarPosition)}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                      >Back</Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleNext}>
                          {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                      </Button>
                  </Box>
              </React.Fragment>
          )}
      </React.Fragment>
    );
  };
  
  const getStepContent = (step, xs, formData, errors, handleChange, selectedImage, setSelectedImage, avatarPosition, setAvatarPosition) => {
    switch (step) {
      case 0:
        return (
          <Step1 isXs={xs} formData={formData} errors={errors} handleChange={handleChange}/>
          
        );
      case 1:
        return (
          <Step2 isXs={xs} formData={formData} handleChange={handleChange} />
        );
      case 2:
        return (
          <Step3 isXs={xs} 
                 formData={formData} 
                 handleChange={handleChange} 
                 selectedImage={selectedImage} 
                 setSelectedImage={setSelectedImage}
                 avatarPosition={avatarPosition} 
                 setAvatarPosition={setAvatarPosition} />
        );
      default:
        return 'Unknown step';
    }
  };
  
  
  const Step1 = ({isXs, formData, errors, handleChange}) => {
      return (
          <Container>
              <Box sx={{ width: '100%' }}>
                  <Container>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                              <Typography
                                  variant="h5"
                                  align="left"
                                  display={{ xs: 'none', md: 'block' }}
                              >
                                Organisation Name
                              </Typography>
                          </Grid>
                          <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                            <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                              <Box>
                                  <Box sx={{marginTop: 0}}>
                                      <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Organisation Name"}</Typography>
                                      <TextField
                                      required
                                      name="name"
                                      label={isXs ? "Organisation Name" : ""}
                                      fullWidth
                                      height="80px"
                                      autoFocus
                                      onChange={handleChange()}
                                      value={formData.name}
                                      error={!!errors.name}
                                      helperText={errors.name}
                                      />
                                  </Box>
                              </Box>
                          </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                              <Typography
                                  variant="h5"
                                  align="left"
                                  display={{ xs: 'none', md: 'block' }}
                              >
                                Contact
                              </Typography>
                          </Grid>
                          <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                            <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                              <Box>
                                  <Box sx={{marginTop: 0}}>
                                      <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Contact full name"}</Typography>
                                      <TextField
                                      required
                                      name="pocname"
                                      label={isXs ? "Contact full name" : ""}
                                      fullWidth
                                      height="80px"
                                      helperText="Please enter the full name of the organisation contact"
                                      onChange={handleChange()}
                                      value={formData.info.pocname}
                                      />
                                  </Box>
                                  <Box sx={{marginTop: 0}}>
                                      <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Contact telephone number"}</Typography>
                                      <TextField
                                      required
                                      name="poctel"
                                      label={isXs ? "Contact telephone number" : ""}
                                      fullWidth
                                      height="80px"
                                      helperText="Please enter a contact telephone number"
                                      onChange={handleChange()}
                                      value={formData.info.poctel}
                                      />
                                  </Box>
                                  <Box sx={{marginTop: 0}}>
                                      <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Contact Email Address"}</Typography>
                                      <TextField
                                      required
                                      name="pocemail"
                                      label={isXs ? "Contact Email Address" : ""}
                                      fullWidth
                                      height="80px"
                                      helperText="Please enter a contact email address for the organisation"
                                      onChange={handleChange()}
                                      value={formData.info.pocemail}
                                      />
                                  </Box>
                              </Box>
                          </Grid>
                      </Grid>
                  </Container>
              </Box>
          </Container>
      );
  };
  
  const Step2 = ({isXs, formData, handleChange}) => {
    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="left"
                                display={{ xs: 'none', md: 'block' }}
                                >
                                Address
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                            <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Address Line 1"}</Typography>
                                    <TextField
                                            required
                                            name="addrline1"
                                            label={isXs ? "Address Line 1" : ""}
                                            fullWidth
                                            height="80px"
                                            helperText="Please specify the first line of your address"
                                            onChange={handleChange()}
                                            value={formData.info.address.addrline1}
                                    />
                                </Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Address Line 2"}</Typography>
                                    <TextField
                                            required
                                            name="addrline2"
                                            label={isXs ? "Address Line 2" : ""}
                                            fullWidth
                                            height="80px"
                                            helperText="Please specify the second line of your address"
                                            onChange={handleChange()}
                                            value={formData.info.address.addrline2}
                                    />
                                </Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Town/City"}</Typography>
                                    <TextField
                                            required
                                            name="towncity"
                                            label={isXs ? "Town/City" : ""}
                                            fullWidth
                                            height="80px"
                                            helperText="Please specify the address Town or City"
                                            onChange={handleChange()}
                                            value={formData.info.address.towncity}
                                    />
                                </Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "County"}</Typography>
                                    <TextField
                                            required
                                            name="county"
                                            label={isXs ? "County" : ""}
                                            fullWidth
                                            height="80px"
                                            helperText="Please specify the address County"
                                            type="select"
                                            onChange={handleChange()}
                                            value={formData.info.address.county}
                                    />
                                </Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Postcode"}</Typography>
                                    <TextField
                                            required
                                            name="postcode"
                                            label={isXs ? "Postcode" : ""}
                                            fullWidth
                                            height="80px"
                                            helperText="Please specify the address postcode"
                                            onChange={handleChange()}
                                            value={formData.info.address.postcode}
                                    />
                                </Box>
                                <Box sx={{marginTop: 0}}>
                                    <Typography variant="h6" sx={{color: "#555555"}}>{isXs ? "" : "Country"}</Typography>
                                    <TextField
                                            required
                                            name="country"
                                            label={isXs ? "Country" : ""}
                                            fullWidth
                                            height="80px"
                                            helperText="Please specify the address country"
                                            type="select"
                                            onChange={handleChange()}
                                            value={formData.info.address.country}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
  };
  
  const Step3 = ({isXs, formData, handleChange, selectedImage, setSelectedImage, avatarPosition, setAvatarPosition}) => {
    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Container>
                <Grid container spacing={2}>
                        <Grid item xs={12} md={4} mt={{ xs: 0, md: 2 }}>
                            <Typography
                                variant="h5"
                                align="left"
                                display={{ xs: 'none', md: 'block' }}
                            >
                              Avatar
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1} mt={{ xs: 0, md: 2 }}>
                          <Box display={{ xs: 'none', md: 'block' }} sx={{width:"100%", height:"100%", borderLeft: '2px solid #dfdfff'}}>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={{ xs: 0, md: 2 }} mb={{ xs: 0, md: 2 }}>
                            <Box sx={{marginTop: 0}}>
                                <AvatarUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} position={avatarPosition} setPosition={setAvatarPosition}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
  };
  export default OrganisationAddForm;