import React, { useState } from 'react';
import { TextField, Chip, Box, Autocomplete, useTheme, useMediaQuery } from '@mui/material';
const ChipAdder = ({options, selected, setSelected}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  const handleChipAdd = (event, newValue) => {
    //console.log(newValue);
    setSelected(newValue);
  };

  return (
    <Box>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option.name}
        value={selected}
        onChange={handleChipAdd}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={isXs ? "Organisation access" : ""}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default ChipAdder;
