async function addUser(dataObj) {
    try {
        const url = "/api/v1/users/add";
        const method = "POST";
        const body = JSON.stringify(dataObj);

        //return seriesData; 
        const response = await fetch(url, {
            method: method,
            body: body,
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        return data.results;
    } catch (error) {
        //console.error('There has been a problem with your fetch operation:', error);
    }
}

export const getUserData = async () => {
    var results = {};
    try {
        const url = "/api/v1/users";

        //return seriesData; 
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        results = data.results;
    } catch (error) {
        //console.error('There has been a problem with your fetch operation:', error);
    }
    return results;
};

export const updateUser = async (data) => {
    const result = await addUser(data);
    return result;
};