import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import { getDeviceList } from '../../data/database';
import Header from "../../components/Header";
import DeviceCard from '../../components/DeviceCard';
import LocationMap from '../../components/LocationMap';
import { useAuth } from '../../components/AuthContext';

/*===========================================================================*/
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff00',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderColor: '#ffffff00'
}));

/*===========================================================================*/
const Location = ({route}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { isAdmin, isOrg } = useAuth();
  const location = useLocation();
  const [devices, setDevices] = useState([]);

  const handleAddDevice = () => {
    navigate(`/organisations/${location.state.orgid}/clients/${location.state.cliid}/locations/${location.state.locid}/devices/add`, 
              {
                state:{
                  ordid: location.state.orgid, 
                  cliid: location.state.cliid,
                  locid: location.state.locid
                }
              });
  };

  useEffect(() => {
    //console.log(location.state.loc.orgid);
    const obj = getDeviceList();
    var arr = [];
    Object.keys(obj).forEach( item => {
      arr.push(obj[item]);
    });
    setDevices(arr);
  }, []);

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={location.state.loc.name} subtitle="Manage devices and their data" />
                { (isAdmin() || isOrg()) && (
                <Button
                  sx={{
                      backgroundColor: "#5562AC",
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: '#7785AC',
                        boxShadow: 'none',
                      }
                  }}
                  onClick={handleAddDevice}
                >
                    <AddIcon sx={{ mr: "10px" }} />
                    Add Device
                </Button>
                )}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Container>
                  <Box sx={{ paddingTop: 4, height: "300px", margin: 2 }}>
                    <LocationMap address={location.state.loc.address}/>
                  </Box>
                </Container>
              </Grid>

              <Grid item xs={12}>
                { /* <ClientsTable /> */ }
                <Container>
                  <Box sx={{ paddingTop: 4 }}>
                    {devices.map((dev) => (
                      <DeviceCard key={dev.id} 
                                  org={location.state.loc.orgid} 
                                  client={location.state.loc.cliid}
                                  location={location.state.loc}
                                  device={dev} />
                    ))}
                  </Box>
                </Container>
              </Grid>
            </Grid>
        </Container>
    </React.Fragment>
  );
}

export default Location;