import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, useTheme} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DevicesTable } from '../../components/DevicesTable';
import { getDevices, getOrganisationList, getAllClientList } from '../../data/database';
import { ColorModeContext, tokens } from "../../theme";
import Header from "../../components/Header";

const Devices = () => {
  //<Chip variant="outlined" onDelete={handleDelete} avatar={<Avatar src="/static/images/avatar/1.jpg" />} />
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [orgList, setOrgList] = useState([]);
  const [cliList, setCliList] = useState([]);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    //console.log("Fetching device data...");
    getOrganisationList().then( (obj) => {
        var arr = [];
        Object.keys(obj).forEach( item => {
            arr.push(obj[item]);
        });
        setOrgList(arr);
    });

    getAllClientList().then( (obj) => {
        var arr = [];
        Object.keys(obj).forEach( item => {
            arr.push(obj[item]);
        });
        setCliList(arr);
    });

    getDevices()
      .then( (deviceData) => {
        //console.log(deviceData);
        setRows(deviceData);
      })
      .catch( (error) => {
        //console.log("Get devices exception " + error);
      })
  }, []);

  const handleAddDevice = () => {
    navigate('/devices/add');
  };

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Devices" subtitle="Manage the devices manufactured, built, tested, and deployed" />
                <Button
                  sx={{
                      backgroundColor: "#5562AC",
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      borderRadius: 10,
                      '&:hover': {
                        backgroundColor: '#7785AC',
                        boxShadow: 'none',
                      }
                  }}
                  onClick={handleAddDevice}
                >
                    <AddIcon sx={{ mr: "10px" }} />
                    Add Device
                </Button>
            </Box>
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DevicesTable rowData={rows} setRowData={setRows} orgs={orgList} clients={cliList}/>
              </Grid>
            </Grid>
        </Container>
    </React.Fragment>
  );
};

export default Devices;