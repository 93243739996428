// WelcomeCard.jsx
import React from 'react';
import { Card, CardContent, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


const SquareCard = styled(Card)(({ theme }) => ({
    borderRadius: '10px',
    width: '90%', // This makes the Card fill the width of the Grid item, keeping the aspect ratio handled by the Grid item
    aspectRatio: '1.5 / 1', // Ensures the Card is square
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  }));
  
const WelcomeCard = ({ org }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/dashboard`, { state: {org: org}});
    };

    return (
        <SquareCard onClick={handleCardClick}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                <Avatar alt={org.name} src={org.avatar} sx={{ backgroundColor: "#aaaaaa", width: '37%', height: '60%', mb: 2 }} />
                                    <Typography 
                                        variant="h6"
                                        sx={{ 
                                            fontWeight: 300,
                                            fontSize: {
                                              xs: '1.5rem',  // 1rem for extra-small screens
                                              sm: '1.25rem', // 1.25rem for small screens
                                              md: '1.5rem',  // 1.5rem for medium screens
                                              lg: '1.75rem', // 1.75rem for large screens
                                              xl: '2rem'   // 2rem for extra-large screens
                                            }
                                          }}>{org.name}</Typography>
                                </CardContent>
                            </SquareCard>
    );
};

export default WelcomeCard;
