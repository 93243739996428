import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { tokens } from "../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Header from "../../components/Header";
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.sigmatiqa.co.uk/">
          Sigmatiqa
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/*<Avatar sx={{ width: 100, height: 100, m: 5, bgcolor: 'background.default' }}></Avatar> */}
          <img src='../../assets/logo2.png' alt="Logo" width="190px" />
          <Typography component="h1" variant="h5">
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
                <Typography variant="h3" sx={{ mb: 3 }} align="center">
                    Sorry, page not found!
                </Typography>
                <Typography sx={{ color: 'text.secondary' }} align="center">
                    Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be
                    sure to check your spelling.
                </Typography>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default Dashboard;