import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Contract = ({title, text}) => {

    return (
        <Paper elevation={3} sx={{ padding: 2, maxHeight: '500px', overflowY: 'auto' }}>
            <Box>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
            </Box>
        </Paper>
      );
};

export default Contract;
