import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {useTheme, Backdrop, CircularProgress} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../components/AuthContext';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.sigmatiqa.co.uk/">
        Sigmatiqa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ForgotPassword() {
    const theme = useTheme();
    const [formValues, setFormValues] = useState({ email: '' });
    const [errors, setErrors] = useState({ email: '' });
    const [loading, setLoading] = useState(false);
    const [statusMsg, setStatusMsg] = useState("");
    const navigate = useNavigate();
    const { isAuthenticated, login, logout, sendPaswordReset } = useAuth();

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: '',
      });
    };

    const validate = () => {
      let tempErrors = { email: '' };
      let valid = true;
  
      if (!formValues.email) {
        tempErrors.email = 'Email is required';
        valid = false;
      } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
        tempErrors.email = 'Email is not valid';
        valid = false;
      }
  
      setErrors(tempErrors);
      return valid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (validate()) {
          const email = formValues.email;
          sendPaswordReset(email)
            .then( (result) => {
              if( result.hasOwnProperty("status") === true ){
                if( result.status === true ){
                  setStatusMsg(result.msg);
                }else{
                  let tempErrors = { email: '' };
                  if (!formValues.email) {
                    tempErrors.email = result.msg;
                  }
                  setErrors(tempErrors);
                  setStatusMsg("");
                }
              }
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/*<Avatar sx={{ width: 100, height: 100, m: 5, bgcolor: 'background.default' }}></Avatar> */}
          <img src='../../assets/logo2.png' alt="Logo" width="190px" />
          <Typography component="h1" variant="h5">
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formValues.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: "48px" }}
            >
              Send Password Reset Email
            </Button>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  {statusMsg} <Link href="/login" variant="body1"> Return to sign-in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}