
import {updateUser } from './users';
import { getData } from './measurements';
import { getEventsData } from './events';
import { gatewayTypes, supportedRegions, networkList } from './gateways';
import { base64url_encode } from './utils';

//import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref, get, query, orderByChild, equalTo, onValue, child } from 'firebase/database';

export const firebaseStorageErrorCodes = [
    {code: "storage/unknown", desc: "An unknown error occurred"},
    {code: "storage/object-not-found", desc: "No file found in storage."},
    {code: "storage/bucket-not-found", desc: "No location found in storage."},
    {code: "storage/project-not-found", desc: "Data not found in storage."},
    {code: "storage/quota-exceeded", desc: "Issue with cloud. Contact your IT support and quote this error."},
    {code: "storage/unauthenticated", desc: "User is unauthenticated, please log back in and try again."},
    {code: "storage/unauthorized", desc: "User is not authorized to perform the desired action, check you have the correct privileges to perform this action."},
    {code: "storage/retry-limit-exceeded", desc: "The maximum time limit on an operation has been excceded. Try uploading again."},
    {code: "storage/invalid-checksum", desc: "Checksum issue in server. Try uploading again."},
    {code: "storage/canceled", desc: "User canceled the operation."},
    {code: "storage/invalid-event-name", desc: "Invalid event name provided. Contact your IT support and quote this error."},
    {code: "storage/invalid-url", desc: "Invalid URL provided. Contact your IT support and quote this error."},
    {code: "storage/invalid-argument", desc: "The argument. Contact your IT support and quote this error."},
    {code: "storage/no-default-bucket", desc: "No storage location. Contact your IT support and quote this error."},
    {code: "storage/cannot-slice-blob", desc: "Local file has changed. Try uploading again after verifying that the file hasn't changed."},
    {code: "storage/server-file-wrong-size", desc: "User and server mismatch error. Try uploading again."}
];

/*=======================================================================*/
/** 
  * @fn getFirebaseData
  * @brief Generic get data from a path
  */
const getFirebaseData = async (url) => {
    /*
        const dbRef = ref(getDatabase(this.app));
        var result = await get(child(dbRef, url)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return undefined;
            }
        }).catch((error) => {
            const errorCode = error.code;
            const errorMsg = error.message;
            if( errorMsg.includes("Permission denied") ){
                this.blocked = true;
                if( this.forceCloseCb != null ) {
                    this.forceCloseCb();
                }
                return "permission";
        }
        return undefined;
    });
    */
    var result = undefined;
    return result;
}

/*=======================================================================*/
/** 
  * @fn getDataFromPath
  * @brief
  */
const getDataFromPath = async (path) => {
    const db = getDatabase();
    const dbRef = ref(db, path);
    try {
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const dataArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key],
        }));
        return dataArray;
      } else {
        //console.log('No data available');
        return [];
      }
    } catch (error) {
      //console.error('Error getting data:', error);
      return [];
    }
};

/*=======================================================================*/
/** 
  * @fn getDataFromDb
  * @brief
  */
const getDataFromDb = async (path) => {
    const db = getDatabase();
    const dbRef = ref(db, path);
    try {
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        //console.log('No data available');
        return {};
      }
    } catch (error) {
      //console.error('Error getting data:', error);
      return {};
    }
};

/*=======================================================================*/
/** 
  * @fn getUsers
  * @brief
  */
export const getUsers = async () => {
    const db = getDatabase();
    const dbRef = ref(db, `darwin/udb/users/list`);
    const userSnapshot = await get(dbRef);
    if( !userSnapshot.exists() ) {
        return undefined;
    }

    let users = userSnapshot.val();
    //console.log(users);

    // Loop through each user
    for (let userId in users) {
        let user = users[userId];

        // Loop through each client ID in the user's clients object
        for (let clientId in user.clients) {
            const clientPath = `darwin/udb/clients/list/${clientId}`;
            const clientRef = ref(db, clientPath);
            const clientSnapshot = await get(clientRef);
            if (clientSnapshot.exists()) {
                user.clients[clientId] = clientSnapshot.val();
            }
        }

        // Loop through each client ID in the user's clients object
        for (let orgId in user.orgs) {
            const orgPath = `darwin/udb/organisations/list/${orgId}`;
            const orgRef = ref(db, orgPath);
            const orgSnapshot = await get(orgRef);
            if (orgSnapshot.exists()) {
                user.orgs[orgId] = orgSnapshot.val();
            }
        }
    }

    const dataArray = Object.keys(users).map(key => ({
      id: key,
      ...users[key],
    }));

    return dataArray;
};

/*=======================================================================*/
/** 
  * @fn getUserData
  * @brief
  */
export const getUserData = async (uid) => {
    //console.log(uid);
    return await getDataFromDb(`darwin/udb/users/list/${uid}`);
};

/*=======================================================================*/
/** 
  * @fn addUserAccess
  * @brief
  */
export const addUserAccess = (data) => {
    return updateUser(data);
};

/*=======================================================================*/
/** 
  * @fn getOrganisationList
  * @brief
  */
export const getOrganisationList = async () => {
    const data = await getDataFromPath(`darwin/udb/organisations/list`);
    return data;
};

/*=======================================================================*/
/** 
  * @fn getOrganisation
  * @brief
  */
export const getOrganisation = async (id) => {
    var data = {};
    const db = getDatabase();
    const dbRef = ref(db, `darwin/udb/organisations/list/${id}`);
    try {
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        data = snapshot.val();
      }
    }catch(err){

    }
    return data;
};

/*=======================================================================*/
/** 
  * @fn getAllClientList
  * @brief
  */
export const getAllClientList = async () => {
    const data = [];
    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/clients/list`);
        const q = query(dbRef);
        const snapshot = await get(q);
        
        snapshot.forEach((childSnapshot) => {
            data.push(childSnapshot.val());
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getClientList
  * @brief
  */
export const getClientList = async (orgid) => {
    const data = [];
    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/clients/list`);
        const q = query(dbRef, orderByChild('orgid'), equalTo(orgid));
        const snapshot = await get(q);
        
        snapshot.forEach((childSnapshot) => {
            data.push(childSnapshot.val());
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getClientData
  * @brief
  */
export const getClientData = async (clientId) => {
    //console.log(clientId);
    return await getDataFromDb(`darwin/udb/clients/list/${clientId}`);
};

/*=======================================================================*/
/** 
  * @fn getOrgData
  * @brief
  */
export const getOrgData = async (orgId) => {
  //console.log(orgId);
  return await getDataFromDb(`darwin/udb/orgs/list/${orgId}`);
};

/*=======================================================================*/
/** 
  * @fn getLocationList
  * @brief
  */
export const getLocationList = async (orgid, cliid) => {
    const data = [];

    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/locations/list`);
        const q = query(dbRef, orderByChild('orgid'), equalTo(orgid));
        const snapshot = await get(q);

        snapshot.forEach((childSnapshot) => {
            const obj = childSnapshot.val();
            if( obj.hasOwnProperty("cliid") === true && 
                obj.cliid === cliid ){
                data.push(childSnapshot.val()); 
            }
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getClientStats
  * @brief
  */
export const getClientStats = async (orgid, cliid) => {
    const data = {};
    data["numdevice"] = {
        "24h": 5,
        "week": 5,
        "month": 7
    };
    data["batterystatus"] = {
        "warningcount": 2,
        "devices": {
            "abcd": {
                "reason":"low"
            },
            "1234": {
                "reason":"low"
            }
        }
    };
    data["sensorstats"] = {
      "temperature": {
        "min": 20,
        "max": 72
      },
      "ambient": {
        "min": 15,
        "max": 38
      }
    };
    data["notifications"] = [
        {
            "type": "",
            "trigger": "",
            "severity": "",
            "msg": "",
            "when": 1721934215,
            "device": {
                "id": ""
            }
        }
    ];

    return data;
};

/*=======================================================================*/
/** 
  * @fn getDeviceTypeList
  * @brief
  */
export const getDeviceTypeList = async () => {
    const data = [];

    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/devices/info/models`);
        const q = query(dbRef);
        const snapshot = await get(q);

        snapshot.forEach((childSnapshot) => {
            const obj = childSnapshot.val();
            if( obj.hasOwnProperty("id") === true ){
                data.push(obj.id); 
            }
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getDeviceList
  * @brief
  */
export const getDeviceList = async () => {
    const data = [];

    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/devices/list`);
        const q = query(dbRef);
        const snapshot = await get(q);

        snapshot.forEach((childSnapshot) => {
            const obj = childSnapshot.val();
            data.push(obj); 
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getClientDeviceList
  * @brief
  */
export const getClientDeviceList = async (cliid) => {
    const data = [];

    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/devices/list`);
        const q = query(dbRef, orderByChild('client'), equalTo(cliid));
        const snapshot = await get(q);

        snapshot.forEach((childSnapshot) => {
            const obj = childSnapshot.val();
            data.push(obj); 
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getLocationDeviceList
  * @brief
  */
export const getLocationDeviceList = async (locid) => {
    const data = [];

    try {
        const db = getDatabase();
        const dbRef = ref(db, `darwin/udb/devices/list`);
        const q = query(dbRef, orderByChild('locid'), equalTo(locid));
        const snapshot = await get(q);

        snapshot.forEach((childSnapshot) => {
            const obj = childSnapshot.val();
            data.push(obj); 
        });
    } catch (error) {
        //console.error('Error getting data:', error);
    }

    return data;
};

/*=======================================================================*/
/** 
  * @fn getClientServiceAgreement
  * @brief
  */
export const getClientServiceAgreement = async (uid) => {
    var serviceAgreementText = "";
    const userObj = await getDataFromDb(`darwin/udb/users/list/${uid}`);

    if( userObj.hasOwnProperty("clients") === true ){
        const clientIds = Object.keys(userObj.clients);
        if( clientIds.length > 0 && userObj.clients[clientIds[0]] === true ){
            const cli = clientIds[0];
            if( cli !== undefined && cli !== null && cli !== "" ){
                serviceAgreementText = await getDataFromDb(`darwin/udb/clients/list/${cli}/serviceagreement/text`);
            }
        }
    }
    return serviceAgreementText;
};

/*=======================================================================*/
/** 
  * @fn getClientPrivacyPolicy
  * @brief
  */
export const getClientPrivacyPolicy = async (uid) => {
  var serviceAgreementText = "";
  const userObj = await getDataFromDb(`darwin/udb/users/list/${uid}`);

  if( userObj.hasOwnProperty("clients") === true ){
      const clientIds = Object.keys(userObj.clients);
      if( clientIds.length > 0 && userObj.clients[clientIds[0]] === true ){
          const cli = clientIds[0];
          if( cli !== undefined && cli !== null && cli !== "" ){
              serviceAgreementText = await getDataFromDb(`darwin/udb/clients/list/${cli}/privacypolicy/text`);
          }
      }
  }
  return serviceAgreementText;
};

/*=======================================================================*/
/** 
  * @fn getNetworks
  * @brief
  */
export const getNetworks = () => {
    return networkList;
};

/*=======================================================================*/
/** 
  * @fn getSupportedNetworkRegions
  * @brief
  */
export const getSupportedNetworkRegions = () => {
    return supportedRegions;
};

/*=======================================================================*/
/** 
  * @fn getSupportedGateways
  * @brief
  */
export const getSupportedGateways = () => {
    return gatewayTypes;
};

/*=======================================================================*/
/** 
  * @fn getMeasurements
  * @brief
  */
export const getMeasurements = async (token, org, cli, loc, devId, startUtc, window) => {
    return await getData(token, org, cli, loc, devId, startUtc, window);
};

/*=======================================================================*/
/** 
  * @fn getEvents
  * @brief
  */
export const getEvents = () => {
    return getEventsData();
};

/*=======================================================================*/
/** 
  * @fn getUsers
  * @brief
  */
export const getDevices = async () => {
    const db = getDatabase();
    const dbRef = ref(db, `darwin/udb/devices/list`);
    const deviceSnapshot = await get(dbRef);
    if( !deviceSnapshot.exists() ) {
        return undefined;
    }

    let devices = deviceSnapshot.val();
    //console.log(devices);

    // Loop through each user
    for (let deviceId in devices) {
        let device = devices[deviceId];

        if( device.hasOwnProperty("org") === true && device.org !== undefined && device.org !== null && device.org !== "" ) {
            const orgPath = `darwin/udb/organisations/list/${device.org}`;
            const orgRef = ref(db, orgPath);
            const orgSnapshot = await get(orgRef);
            if (orgSnapshot.exists()) {
                devices[deviceId].org = orgSnapshot.val();
            }
        }

        if( device.hasOwnProperty("client") === true && device.client !== undefined && device.client !== null && device.client !== "" ) {
            const clientPath = `darwin/udb/clients/list/${device.client}`;
            const clientRef = ref(db, clientPath);
            const clientSnapshot = await get(clientRef);
            if (clientSnapshot.exists()) {
                devices[deviceId].client = clientSnapshot.val();
            }
        }

        if( device.hasOwnProperty("locid") === true && device.locid !== undefined && device.locid !== null && device.locid !== "" ) {
            const locPath = `darwin/udb/locations/list/${device.locid}`;
            const locRef = ref(db, locPath);
            const locSnapshot = await get(locRef);
            if (locSnapshot.exists()) {
                devices[deviceId].locid = locSnapshot.val();
            }
        }
    }

    const dataArray = Object.keys(devices).map(key => ({
        id: key,
        ...devices[key],
    }));

    return dataArray;
};

/*=======================================================================*/
/** 
  * @fn addDevice
  * @brief
  */
export const addDevice = (id, model, ) => {
    
};

/*=======================================================================*/
/** 
  * @fn assignDeviceDetails
  * @brief
  */
export const assignDeviceDetails = (id, customer, client, location, name) => {
    const obj = {
        customer: base64url_encode(customer),
        client: base64url_encode(client),
        location: base64url_encode(location),
        name: base64url_encode(name)
    };
};