// BatteryHealthCard.jsx
import React from 'react';
import { Card, CardContent, Typography, LinearProgress, Box, useTheme } from '@mui/material';

const BatteryHealthCard = ({ min, max, avg }) => {
  const theme = useTheme();

  return (
    <Card 
      sx={{ 
        px: 3, 
        py: 5, 
        borderRadius: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" align="center">
          Battery Health
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" component="div">
            Min: {min}V
          </Typography>
          <LinearProgress
            variant="determinate"
            value={min*100/3.35}
            sx={{ height: 10, borderRadius: 5, mt: 1 }}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" component="div">
            Max: {max}V
          </Typography>
          <LinearProgress
            variant="determinate"
            value={max*100/3.35}
            color="secondary"
            sx={{ height: 10, borderRadius: 5, mt: 1 }}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1" component="div">
            Average: {avg}V
          </Typography>
          <LinearProgress
            variant="determinate"
            value={avg*100/3.35}
            color="success"
            sx={{ height: 10, borderRadius: 5, mt: 1 }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default BatteryHealthCard;
