import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { tokens } from "../../theme";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import {MeasurementChart} from '../../components/MeasurementChart';
import BatteryHealthCard from '../../components/BatteryHealthCard';
import { useAuth } from '../../components/AuthContext';

const Device = ({route}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, isOrg } = useAuth();

  return (
    <React.Fragment>
        <CssBaseline />
        <Container>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={location.state.device.alias} subtitle="View and edit device data" />
            </Box>
            <MeasurementChart devEui={location.state.device.id.toLowerCase()} devConfig={location.state.device.config} />
            <BatteryHealthCard min={3.306} max={3.312} avg={3.310} />
        </Container>
    </React.Fragment>
  );
};

export default Device;