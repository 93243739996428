// src/components/LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Decimation } from 'chart.js';
import dayjs from 'dayjs';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin,
  Decimation
);

const LineChart = ({ xAxisData, 
                     yAxisData = [], 
                     seriesStyles = [
                        {colour: 'rgb(205, 50, 205)'}, 
                        {colour: 'rgb(0, 0, 205)'}, 
                        {colour: 'rgb(46, 139, 87)'}, 
                        {colour: 'rgb(220, 20, 60)'}, 
                        {colour: 'rgb(0,0,205)'}, 
                        {colour: 'rgb(46, 139, 87)'}, 
                        {colour: 'rgb(220, 20, 60)'}
                     ]
                    }) => {

  const points = yAxisData;

  const data = {
    labels: xAxisData,
    datasets: ((points.length > 0)) ? points.map((yData, index) => ({
      label: yData.id,
      data: yData.data.map((value, idx) => ({ x: xAxisData[idx], y: value })),
      fill: false,
      borderColor: yData.id === "Hot Flow" ? seriesStyles[3].colour : (yData.id === "Cold" ? seriesStyles[1].colour : seriesStyles[index].colour),
    })) : []
  };

  const options = {
    parsing: false,
    scales: {
      x: {
        type: 'time',
        time: {
            unit: 'hour',
            displayFormats: {
                hour: 'dd/MM HH:mm', // Format for displaying hours
            }
        },
        title: {
            display: true,
            text: 'Date',
        },
        ticks: {
            source: 'auto',
            autoSkip: true,
            maxTicksLimit: 24,
            maxRotation: 0
        }
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Temperature (°C)',
        },
        ticks: {
            callback: function(value) {
              return `${value}°C`;
            }
          }
      },
    },
    plugins: {
        decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 200
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x', // Allow panning in the x direction
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true
            },
            mode: 'x', // Allow zooming in the x direction
          }
        }
      },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: '350px', width: '100%' }}>
      <Line data={data} options={options} type={'line'} />
    </div>
  );
};

export default LineChart;